const body = document.body;
const cmtId = 's905';
const toolToken = 'tool_google_tag_manager';
let gtmLoaded = false;

export class GoogleTagManager {

  load() {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PNL2L5G');

    let noScriptNode = document.createElement('noscript');
    let iframeNode = document.createElement('iframe');

    iframeNode.src = 'https://www.googletagmanager.com/ns.html?id=GTM-PNL2L5G';
    iframeNode.height = 0;
    iframeNode.width = 0;
    iframeNode.style = 'display:none;visibility:hidden';

    noScriptNode.append(iframeNode);
    body.append(noScriptNode);
    gtmLoaded = true;
  }

  loadDev() {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=sVwTtXRhFk3r2Lbl2Xavcg&gtm_preview=env-92&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PNL2L5G');

    let noScriptNode = document.createElement('noscript');
    let iframeNode = document.createElement('iframe');

    iframeNode.src = 'https://www.googletagmanager.com/ns.html?id=GTM-PNL2L5G&gtm_auth=sVwTtXRhFk3r2Lbl2Xavcg&gtm_preview=env-92&gtm_cookies_win=x';
    iframeNode.height = 0;
    iframeNode.width = 0;
    iframeNode.style = 'display:none;visibility:hidden';

    noScriptNode.append(iframeNode);
    body.append(noScriptNode);
  }

  destroy(){
    window.dataLayer = {};
    gtmLoaded = false;
  }

  getCmtId() {
    return cmtId;
  }

  getToolToken() {
    return toolToken;
  }

  isLoaded() {
    return gtmLoaded;
  }

}


