import { ConsoleDebug } from '../../../_utils/ConsoleDebug';
import { Environment } from '../../../_utils/Environment';
import { View } from 'pacto';
import { Picture } from "../../picture/views/Picture";

const TEST_ARTICLE_ID = 1753625;
const EXTVAR_REDFACTTEMPLATE_LIST_BINEOS_VERTICAL = 'contentbox/list-bineos-vertical';
const EXTVAR_REDFACTTEMPLATE_LIST_BINEOS_SLIDER = 'contentblock/list-bineos-slider';
const EXTVAR_REDFACTTEMPLATE_LIST_GREATFURTHERREAD = 'article/list-greatfurtherread';
const CLASS_ITEM_WRAPPER_LIST_BINEOS_VERTICAL = 'list-bineos-vertical__content';
const CLASS_ITEM_WRAPPER_LIST_BINEOS_SLIDER = 'carousel__content';
const CLASS_ITEM_WRAPPER_LIST_GREATFURTHERREAD = 'list-greatfurtherread__contents';

export class BineosPlacement extends View {

  constructor(options) {
		super(options);

    // the zoneUid are provided by bineos e.g. <bineos-zone uid="v74i1vb4j4a3"
    this.zoneUid = options.zoneUid;
    this.itemCounter = 0;

    this.envInstance = new Environment();
    this.logInstance = new ConsoleDebug('debug-bineos', 'BineosPlacement');

    this.appendItem = this.appendItem.bind(this);
    this.getItemRenderingTemplateName = this.getItemRenderingTemplateName.bind(this);
    this.finish = this.finish.bind(this);
	}

  render() {

    this.logInstance.log('BineosPlacement render() called');
    this.registerItemElements = []; // Das Array hilft den 1. Teaser als M zu rendern
    this.extVarRedfactTemplate = this.el.getAttribute('extvar-redfacttemplate'); // Hilft bei der Bestimmung des Wrapper-Elements und dem Artikel Teaser redFACT-Template

    // determine item target element
    let itemWrapperClass = '';
    switch (this.extVarRedfactTemplate) {
      case EXTVAR_REDFACTTEMPLATE_LIST_BINEOS_VERTICAL:
        itemWrapperClass = CLASS_ITEM_WRAPPER_LIST_BINEOS_VERTICAL;
        break;
      case EXTVAR_REDFACTTEMPLATE_LIST_BINEOS_SLIDER:
        itemWrapperClass = CLASS_ITEM_WRAPPER_LIST_BINEOS_SLIDER;
        break;
      case EXTVAR_REDFACTTEMPLATE_LIST_GREATFURTHERREAD:
        itemWrapperClass = CLASS_ITEM_WRAPPER_LIST_GREATFURTHERREAD;
        break;
    }

    this.itemsWrapperEl = document.querySelector('.' + itemWrapperClass + '[data-bineos-uid="' + this.zoneUid + '"]');
    if ( this.itemsWrapperEl === null ) {
      this.logInstance.log('items wrapper element not found for extvar-redfacttemplate ' + this.extVarRedfactTemplate);
    }
  }

  getItemRenderingTemplateName () {
    let templateName = '';
    switch (this.extVarRedfactTemplate) {
      case EXTVAR_REDFACTTEMPLATE_LIST_BINEOS_VERTICAL:
        templateName = this.registerItemElements.length === 0 ? 'teaser-m-article-dynamic' : 'teaser-s-article-dynamic';
        break;
      case EXTVAR_REDFACTTEMPLATE_LIST_BINEOS_SLIDER:
        templateName = 'teaser-m-contentslider';
        break;
      case EXTVAR_REDFACTTEMPLATE_LIST_GREATFURTHERREAD:
        templateName = 'teaser-sm-article-default';
        break;
      default:
      this.logInstance.log('item rendering template name not found');
    }
    return templateName;
  }

  appendItem(productItem) {
    this.logInstance.log('appendItem() called');
    //console.log(productItem);
    this.itemCounter++;

    const maxItems = this.el.getAttribute('extvar-max-items');
    if ( maxItems !== null && this.itemCounter > maxItems ) {
      this.logInstance.log('appendItem() abbrechen, da die max. Anzahl an Items \''+maxItems+'\' erreicht ist, siehe Attribute extvar-max-items');
      return false;
    }

    // Bineos kennt nur die Artikel-IDs aus der redFACT Live-Datenbank, ... um keine CORS-Probleme zu erhalten, teste/überschreibe ich immer mit dem selben Test-Artikel
    if ( this.envInstance.isTestDatabaseEnvironment() ) {
      productItem.productId = TEST_ARTICLE_ID;
    }

    // Die URL für das Rendering des Artikel-Teaser muss manuell zusammengebaut werden, ähnlicher Pfaf wie im redFACT ESI-Include
    // z.B. https://www.mannheimer-morgen.de/redFACT/REST/frontend/render/ar/###datasetId###/teaser-s-article-dynamic/86400/7/ssi?id=853&pageid=2031&puid=3
    let fetchInputUrl = window.location.origin;
    fetchInputUrl += '/redFACT/REST/frontend/render/ar/' + productItem.productId + '/';
    fetchInputUrl += this.getItemRenderingTemplateName();
    fetchInputUrl += '/86400/7/ssi';
    fetchInputUrl += '?id=' + productItem.productId;

    if ( typeof window.nfyJsonParams === "object"
      && typeof window.nfyJsonParams.pageid === 'number'
      && typeof window.nfyJsonParams.puid === 'number' ) {
      fetchInputUrl += '&pageid=' + window.nfyJsonParams.pageid;
      fetchInputUrl += '&puid=' + window.nfyJsonParams.puid;
    }

    if ( fetchInputUrl === '' ) {
      this.logInstance.log('appendItem() abgebrochen, weil "fetchInputUrl" leer ist');
      return false;
    }

    // Wrapper div.item für Artikel Teaser außerhalb von window.fetch bereits erstellen, damit der Content
    // in der korrekten Reihenfolge eingefügt wird auch wenn die Server-Response mal bei einem Teaser länger dauert
    const newItemEl = document.createElement('div');
    newItemEl.classList.add('item');
    this.itemsWrapperEl.appendChild(newItemEl);
    this.registerItemElements.push(newItemEl);

    window.fetch(fetchInputUrl, { credentials: 'include' })
      .then((data) => data.text())
      .then((data) => {
        // DocumentFragment auf Basis des HTML-Codes erstellen (schneller als viele DOM-Änderungen)
        const range = document.createRange();
        range.selectNode(document.querySelector('body'));
        const fragment = range.createContextualFragment(data);

        // Linkziel-Vorgabe von Bineos anwenden, falls vorhanden. Siehe placement.data.productLoop.[].clickurl
        if ( typeof productItem.clickurl === 'string'
          && productItem.clickurl !== '' )
        {
          fragment.querySelector('a[class$="__link__element"]')?.setAttribute('href', productItem.clickurl);
        }

        // DocumentFragment im DOM einfügen
        newItemEl.appendChild(fragment);

        // ggf. vorhandene Bilder initialisieren
        const newItemPicNodeList = newItemEl.querySelectorAll('.picture');
        if ( newItemPicNodeList.length !== 0 ) {
          Array.from(newItemPicNodeList).forEach((newItemPicEl) => {
            const { context } = this;
            const picView = new Picture({ context, el: newItemPicEl });
            picView.render();
          });
        }
      })
      .catch((error) => {
        this.logInstance.log('fetch error => ' + error);
      });

  }

  finish(){
    this.logInstance.log('finish() called');
    if ( this.extVarRedfactTemplate === EXTVAR_REDFACTTEMPLATE_LIST_BINEOS_SLIDER )
    {
      this.logInstance.log('dispatchEvent CustomEvent \'customRenewCarouselView\'');
      this.el.dispatchEvent(new CustomEvent("customRenewCarouselView"));
    }
    else if ( this.extVarRedfactTemplate === EXTVAR_REDFACTTEMPLATE_LIST_GREATFURTHERREAD )
    {
      // special handling show headline in greatfurtherread, if bineos item added
      const headline = document.querySelector('.list-greatfurtherread__headline[data-bineos-uid="' + this.zoneUid + '"]');
      if ( headline != null ) {
          headline.style.display = 'block';
      }
    }
  }
}
