import { View } from 'pacto';
import { ConsoleDebug } from '../../../_utils/ConsoleDebug';
import { Environment } from '../../../_utils/Environment';
import { Commercial  } from '../views/Commercial';
import { RedfactHelper } from "../../../_utils/RedfactHelper";

const debug = new ConsoleDebug('debug-commercial', 'Commercial');
const env = new Environment();
const adObj = new Commercial();
const redfactHelperObj = new RedfactHelper();
const ADSERVER_URL = '//cdn.netpoint-media.de/'
const CTXT_KEY_EVENT_CMP_LIVE = 'event:cmp_live';

const VENDOR_ID = '982';
const toolToken = 'adserver_netpoint';

const PUID_MM = 3;
const PUID_BA = 5;
const PUID_SZ = 6;
const PUID_FN = 7;

let loaded = false;

export class Action {

  run() {
    debug.log('Commercial component is live.');

    if(!redfactHelperObj.checkBackendApproval(this.getToolToken())) {
      debug.log('Ads wurden via redFACT abgestellt.');
      return false;
    } else {
      debug.log('Ads wurden via redFACT erlaubt.');
    }


    const obj = this;
     this.context.on(CTXT_KEY_EVENT_CMP_LIVE, (event) => {
      debug.log('CMP is nun live, starte AdScript', event);
      const cmpObj = this.context.values.get('cmp:settings').cmp;

      let hasAccess = this.context.values.get('cmp:hasAccess').hasAccess;
      window.__tcfapi("addEventListener",2, () => {
        hasAccess = this.context.values.get('cmp:hasAccess').hasAccess;
        if(!hasAccess) {
          debug.log('User hat KEIN aktives Abo =>  Adscript laden');
           if(!cmpObj.checkServiceConsent(VENDOR_ID, obj)) {
             obj.destroy();
           }
        } else {
           debug.log('User HAT aktives Abo => kein Adscript laden');
           obj.destroy();
        }
      });
      adObj.render(hasAccess);
    });

  }

  load(){
    let adScriptName = this.getAdJsByPu(env.getCurrentPuId());
    debug.log('Folgendes Werbescript wurde aufgrund Titel ausgesucht:', ADSERVER_URL+adScriptName);
    // Anreicherung und Ausgabe des Adserver/Advertiser-Tags
    this.bineosAdvertiser(ADSERVER_URL + adScriptName, window.advertiserConf);
  }

  destroy() {
    debug.log('Consent entzogen, destroy wurde aufgerufen');
    this.cmpObj.setVendorConsent(VENDOR_ID, 0);
  }

  isLoaded() {
    return loaded;
  }

  bineosAdvertiser(url, conf) {
      // Append data from bineos
      try {
          var bineosAdvertiserTargetingParameters = JSON.parse(localStorage.getItem('bineosAdvertiserTargetingParameters')) || {};
          for (var key in bineosAdvertiserTargetingParameters) {
              conf.targeting.push({
                  "key": key,
                  "values": null,
                  "value": bineosAdvertiserTargetingParameters[key].value
              });
          }
      } catch(e) {
        debug.log('BineosAdTargeting konnte nicht geparsed werden: '+ e);
      }

      // Append advertiser tag
      let adSetupScript = document.createElement('script');
      adSetupScript.src = url;
      adSetupScript.type = 'text/javascript';
      adSetupScript.async = true;
      document.head.appendChild(adSetupScript);

      window.ADNPM = window.ADNPM || {};ADNPM.cmd = ADNPM.cmd || [];
  }

  getAdJsByPu(pu) {
    switch (pu){
      case PUID_BA:
        return '1270787.js';
      case PUID_SZ:
        return '1270788.js';
      case PUID_FN:
        return '1270789.js';
      case PUID_MM:
      default:
        return '1270728.js';
    }
  }

  getToolToken() {
    return toolToken;
  }

}
