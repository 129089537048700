import { Analytics } from '../service/Analytics';
import { Environment } from "../../../_utils/Environment";
import { ConsoleDebug } from '../../../_utils/ConsoleDebug';
import { RedfactHelper } from "../../../_utils/RedfactHelper";

// Wurde ausgebaut am 11.04.22: SP-592, neue Trackingvariante aktiviert
//import { Ivw } from '../service/Ivw';
import { IvwUpdate } from '../service/IvwUpdate';
// import { Optimize } from '../service/Optimize'; - wurde am 30.09 von Google abgeschalten
import { GoogleTagManager } from '../service/GoogleTagManager';
import { FacebookPixel } from '../service/FacebookPixel';
import { Mouseflow } from '../service/Mouseflow';
import { Kilkaya } from '../service/Kilkaya';
import { Taboola } from "../service/Taboola";
import { Cleverpush } from "../service/Cleverpush";
import { BineosService } from '../service/BineosService';
import { Smartbanner } from "../service/Smartbanner";
import { Piano } from "../service/Piano";

const CTXT_KEY_EVENT_CMP_LIVE = 'event:cmp_live';

export class Action {

  run() {
    this.logInstance = new ConsoleDebug('debug-tracking', 'Tracking');
    this.context.on(CTXT_KEY_EVENT_CMP_LIVE, (event) => {
      this.logInstance.log('CMP is nun live, starte Tracking', event)
      this.loadTracking();
    })
  }

  loadTracking() {
    this.obj = this;
    this.redfacthelper = new RedfactHelper();

    this.logInstance.log('Component live');

    // cmp in redFACT active?
    if (!this.redfacthelper.checkBackendApproval('tool_cmp')) {
      this.logInstance.log('CMP was deactivated');
      return false;
    }

    this.logInstance.log('CMP is active via Backoffice');
    this.cmpObj = this.context.values.get('cmp:settings').cmp;
    this.logInstance.log('Context / cmp:settings: ', this.cmpObj);
    this.hasAccess = this.context.values.get('cmp:hasAccess').hasAccess;

    const environment = new Environment();

    // Creating tracking service objects
    // const optimizeObj = new Optimize(); wurde am 30.09 von Google abgeschalten
    const googleTagManagerObj = new GoogleTagManager();
    const analytics = new Analytics();
    const fbPixel = new FacebookPixel();
    const ivwUpdate = new IvwUpdate();
    const mouseflow = new Mouseflow();
    const taboola = new Taboola();
    const kilkaya = new Kilkaya();
    const cleverpush = new Cleverpush();
    const bineosService = new BineosService(this.context);
    const smartbannerService = new Smartbanner();
    const piano = new Piano();

    // no consent but backend approval necessary for these services
    this.firstPartyServices = [kilkaya, bineosService, piano]
    this.firstPartyServices.forEach((service) => {
        if (this.redfacthelper.checkBackendApproval(service.getToolToken())) {
          service.load();
        } else {
          this.logInstance.log(service.getToolToken() + ' globally disabled in publication settings (redFACT)')
        }
      })

    smartbannerService.load();
    // array with tracking serivce objects for consent checks
    this.serviceConsentObjs = [googleTagManagerObj, analytics, fbPixel, mouseflow, taboola, cleverpush];

    // Check if cmt object exists
    this.logInstance.log('__cmp Object ist da');
    this.logInstance.log('__tcfapi: ' + window.__tcfapi);

    window.__tcfapi("addEventListener",2, () => {
      this.checkConsents();
      if(analytics.isLoaded()) {
          // GA Ereignisse
          this.obj.addEvent('sharing', 'share', analytics);
          this.obj.addEvent('top-navigation', 'click', analytics);
          this.obj.addEvent('topic-navigation', 'click', analytics);
          this.obj.addEvent('domain-navigation', 'click', analytics);
          this.obj.addEvent('Footer', 'click', analytics);
          this.obj.addEvent('Teaser-xl-article-default', 'click', analytics);
          this.obj.addEvent('Teaser-l-article-default', 'click', analytics);
          this.obj.addEvent('Teaser-m-article-default', 'click', analytics);
          this.obj.addEvent('Teaser-s-article-default', 'click', analytics);
          this.obj.addEvent('Teaser-xs-article-default', 'click', analytics);
          this.obj.addEvent('sticky-banner', 'click', analytics);
        }
    });

    // Check if ivw_cfg redfact obj exists to trigger ivw tracking
    if (typeof window.ivw_cfg === 'object') {
      //ivw.load(window.ivw_cfg); // standard ivw tracking
      ivwUpdate.load(window.ivw_cfg); // new ivw tracking 2022.
    }

    this.cmpObj.hideEmbedConsentBar(this.hasAccess)
  }

  // Eventtracking from espi
  addEvent(category, action, analytics) {
    this.context.on(`${category}:${action}`, (event) => {
      const { target } = event.data;
        analytics.trackEvent(category, action, target);
    });
  }

  checkConsents() {
      this.logInstance.log('__tcfapi Event was triggered');
      this.serviceConsentObjs.forEach( (item) => {
        if(this.redfacthelper.checkBackendApproval(item.getToolToken())) {
          this.logInstance.log('Backend Approval was given => Token: ' + item.getToolToken());
          this.cmpObj.checkServiceConsent(item.getCmtId(), item);
        } else {
          this.logInstance.log('Backend Approval was NOT given => Token: ' + item.getToolToken());
        }
      });
      return true;
  }
}
