import { View } from 'pacto';
import { ConsoleDebug } from "../../../_utils/ConsoleDebug";
import { Environment } from "../../../_utils/Environment";


// @TODO: Use BEM helper here:
const SELECTOR_TARGET = '.picture__target';
const CLASS_IMAGE = 'picture__image';
const CLASS_LOADING = 'picture--loading';
const CLASS_READY = 'picture--ready';
const CLASS_ERROR = 'picture--error';


export class Picture extends View {
  constructor(options) {
    super(options);
    this.onIntersect = this.onIntersect.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onError = this.onError.bind(this);
    this.logInstance = new ConsoleDebug('debug-picture', 'Picture');
    this.env = new Environment();
  }

  render() {
    const observer = new IntersectionObserver(this.onIntersect, { threshold: [0, 1] });
    observer.observe(this.el);
    this.observer = observer;
  }

  destroy() {
    this.disconnect();
  }

  disconnect() {
    if (!this.observer) {
      return;
    }

    this.observer.disconnect();
    this.observer = null;
  }

  load() {
    const { el } = this;
    const { alt, srcset } = el.dataset;
    const steps = JSON.parse(srcset);

    const picture = document.createElement('picture');
    picture.className = CLASS_IMAGE;

    if ( this.env.isStaticFractalBuild() ) {
      this.logInstance.log('location pathname identical with static fractal build, then edit img.src by adding \'/pu_mm_2020/espi-dist\' before \'__example__/\'');
    }

    steps.forEach((step, index) => {
      const source = document.createElement('source');
      const src = step['2x'] // List contains 2x DPR sources
        ? `${step['2x']} 2x, ${step['1x']} 1x`
        : `${step['1x']} 1x`;
      const media = (index < steps.length - 1)
        ? `(max-width: ${step.viewport}px)`
        : '';

      // WEB-67 workaround: if location pathname identical with static fractal build, then edit picture src by adding '/pu_mm_2020/espi-dist' before '__example__/'
      if ( this.env.isStaticFractalBuild() ) {
        source.setAttribute('srcset', '/pu_mm_2020/espi-build' + src);
      } else {
        source.setAttribute('srcset', src);
      }

      source.setAttribute('media', media);
      picture.appendChild(source);
    });

    const img = document.createElement('img');
    img.alt = alt;
    img.src = steps[steps.length - 1]['1x'];
    img.onload = this.onLoad;
    img.onerror = this.onError;
    picture.appendChild(img);

    el.classList.add(CLASS_LOADING);
    el.querySelector(SELECTOR_TARGET).appendChild(picture);
  }

  onIntersect(entries) {
    // Find at least one entry which is intersecting:
    const isVisible = entries.reduce(
      (acc, { isIntersecting }) => acc || isIntersecting,
      false,
    );

    if (!isVisible) {
      return;
    }

    this.disconnect();
    this.load();
  }

  onLoad(event) {
    const { target } = event;
    target.onload = null;
    target.onerror = null;

    const { classList } = this.el;
    classList.remove(CLASS_LOADING);
    classList.add(CLASS_READY);
  }

  onError(event) {
    const { target } = event;
    target.onload = null;
    target.onerror = null;

    const { classList } = this.el;
    classList.remove(CLASS_LOADING);
    classList.add(CLASS_ERROR);
  }
}
