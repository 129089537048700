/*Mouseflow ist ein Webanalyse-Tool das Nutzerverhalten auf einer Website zu analysieren und entsprechende Optimierungen an der User Experience vorzunehmen.
Unser Angebot umfasst Session Replays/Aufzeichnungen, Heatmaps, Funnel- und Formularanalyse, sowie Feedback-Kampagnen*/

const body = document.body;
const cmtId = 's2441';
const toolToken = 'tool_mouseflow';
let mouseflowLoaded = false;

export class Mouseflow {

  load() {

    window._mfq = window._mfq || [];
    (function() {
      var mf = document.createElement("script");
      mf.type = "text/javascript"; mf.defer = true;
      mf.src = "//cdn.mouseflow.com/projects/4fe03209-01b5-4619-bf04-ef268a3a3f07.js";
      document.getElementsByTagName("head")[0].appendChild(mf);
    })();

    mouseflowLoaded = true;
  }

  destroy(){
    this.destroyCookie('mf_');
    mouseflowLoaded = false;
  }

  getCmtId() {
    return cmtId;
  }

  getToolToken() {
    return toolToken;
  }

  isLoaded() {
    return mouseflowLoaded;
  }
  destroyCookie(cookieName) {
      let domain = document.location.hostname.replace(/^[^.]+/g, "");
      let expires = "expires=Thu, 01 Jan 1970 00:00:00 GMT"
      document.cookie = cookieName + "='';" + expires + ";domain="+domain+";path=/;";
      return true;
  }
}


