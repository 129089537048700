const billboardContainerId = '#first-billboard';
const urbanChildId = '#urban-leaderboard';
let firstCheck = false;

export class Billboard {

  render() {
    let billboardContainerEl = document.querySelector(billboardContainerId);
    let urbanLeaderboardEl = document?.querySelector(urbanChildId);
    let billdboardAdInjContainer = urbanLeaderboardEl?.firstElementChild;

    if(urbanLeaderboardEl != null){
      let observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          if(!firstCheck){
            if (mutation.type == "attributes" && mutation.attributeName == 'data-google-query-id') {
              if(urbanLeaderboardEl.getAttribute('data-google-query-id') == null){
                billboardContainerEl.style.height = '0px';
              }else{
                firstCheck = true;
              }
            }
          }
        });
      });

      observer.observe(urbanLeaderboardEl, {
        attributes: true //configure it to listen to attribute changes
      });
    }

  }

}
