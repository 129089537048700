import {Environment} from "./Environment";

export class ConsoleDebug {

  /**
   * constructor to create ConsoleDebug instance
   * @param {String} matcher URL-Fragment um Log explizit zu rendern e.g. "#debug-ads", "#debug-pay" or "#debug-carousel"
   * @param {String} className Name der JavaScript Klasse übergeben e.g. "Pay" or "Carousel"
   * @return {object} ConsoleDebug instance
   */
  constructor(matcher, className) {
    this.envInstance = new Environment();
    this.debugModeEnabled = false;
    this.debugClassName = '';
    if ( typeof matcher === 'string' && matcher !== ''
      && typeof className === 'string' && className !== '') {
      var re = new RegExp(matcher, 'g');
      if ( window.location.hash.match(re) !== null
        || this.envInstance.isLocalhost()
        || location.host.match(/^(xmedias|test|localhost|integration|prelive)/) !== null ) {
        this.debugModeEnabled = true;
        this.debugClassName = className;
      }
    }
  }

  enableDebugMode() {
    this.debugModeEnabled = true;
  }

  disableDebugMode() {
    this.debugModeEnabled = false;
  }

  // Funktion gibt "true" zurück, wenn der debugMode aktiv ist oder ein entsprechender Hash vorhanden ist
  isDebugMode() {
    return this.debugModeEnabled;
  }

  log (message = '', obj) {
    if ( this.isDebugMode() ) {
      // eslint-disable-next-line no-console
      if( obj !== undefined ) {
        console.log('[' + this.debugClassName + '] ' + message, obj);
      } else {
        console.log('[' + this.debugClassName + '] ' + message);
      }
    }
  }

  error (message = '', obj) {
    // eslint-disable-next-line no-console
    if( obj !== undefined ) {
      console.error('[' + this.debugClassName + '] ' + message, obj);
    } else {
      console.error('[' + this.debugClassName + '] ' + message);
    }
  }
}
