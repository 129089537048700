import { Initialize } from 'pacto';

import { Header as View } from '../views/Header';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.header',
      namespace: 'header:views',
      view: View,
    };
  }
}
