import { Initialize } from 'pacto';
import { Cmp as View } from '../views/Cmp';

export class Action extends Initialize {
  get settings() {
    return {
      selector: '.cmpDefault',
      namespace: 'cmp:views',
      view: View,
    };
  }
}

