const body = document.body;
const cmtId = 's7';
const toolToken = 'tool_facebook_pixel';
let fbPixelLoaded = false;

export class FacebookPixel {

  load() {
     !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];
     s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '609868845818725');fbq('track', 'PageView');
     fbPixelLoaded = true;
  }

  destroy(){
    this.destroyCookie('_fbp');
    fbPixelLoaded = false;
  }

  getCmtId() {
    return cmtId;
  }

  getToolToken() {
    return toolToken;
  }

  isLoaded() {
    return fbPixelLoaded;
  }

  destroyCookie(cookieName) {
      let domain = document.location.hostname.replace(/^[^.]+/g, "");
      let expires = "expires=Thu, 01 Jan 1970 00:00:00 GMT"
      document.cookie = cookieName + "='';" + expires + ";domain="+domain+";path=/;";
      return true;
  }

}


