const skyLeft = '#skyscraper-left';
const skyRight = '#skyscraper-right';
let maxSkyscraperWidth = 300;

let bodyWidth = document.querySelector('body')?.offsetWidth;
let bodyHeight = document.querySelector('body')?.offsetHeight
const footerHeight = document.querySelector('footer')?.offsetHeight
const skyscraperWrapperOffset =  document.querySelector('#inhalt')?.offsetTop;

let sideWidth = 0;
let contentWidth = 0;
let skyscraperPlaced = false;
let skyscraperArray = [];

export class Skyscraper {

  render() {

    if(window.nfyMaxContentWidth){
      contentWidth = window.nfyMaxContentWidth;
    }else{
      contentWidth = 1176;
    }

    if(window.innerWidth > 728){
      let skyLeftEl = document.querySelector(skyLeft);
      let skyRightEl = document.querySelector(skyRight);

      // usage for different scrope
      let obj = this;

      this.docReady( () => {
        sideWidth = (bodyWidth - contentWidth) / 2;

        if(skyLeftEl != null){
          skyscraperArray.push(skyLeftEl);
          obj.positionLeft(skyLeftEl);
        }

        if(skyRightEl != null){
          skyscraperArray.push(skyRightEl);
          obj.positionRight(skyRightEl);
        }

        window.addEventListener("scroll", (event) => {
          if(skyscraperArray.length > 0){
            let maxScroll = (document.querySelector('footer').offsetTop - window.scrollY) - skyscraperArray[0].offsetHeight;
            if(window.scrollY > 380) {
              if(maxScroll < 140) {
                  if (skyscraperPlaced == false) {
                    skyscraperArray.forEach(element =>  obj.positionTop(element, 'end'));
                    skyscraperPlaced = true;
                  }
              } else {
                skyscraperPlaced = false;
                 skyscraperArray.forEach(element =>  obj.positionTop(element, 'start'));
              }
            }else{
              skyscraperArray.forEach(element =>  obj.positionTop(element, 'top'));
            }
          }
        });
      });

      window.addEventListener('resize', () => {
        if(skyscraperArray.length > 0) {
          bodyWidth = document.querySelector('body')?.offsetWidth;
          bodyHeight = document.querySelector('body')?.offsetHeight

          sideWidth = (bodyWidth - contentWidth) / 2;
          if (skyLeftEl != null) {
            obj.positionLeft(skyLeftEl);
          }
          if (skyRightEl != null) {
            obj.positionRight(skyRightEl);
          }
        }
      });
    }
  }

  positionRight(ele) {
    let posX =  Number(contentWidth) + Number(sideWidth);
    ele.style.top = skyscraperWrapperOffset+'px';
    ele.style.left = posX + 'px';
  }

  positionLeft(ele) {
    //let posX = Math.abs((Number(sideWidth) - Number(maxSkyscraperWidth)));
    let posX = Number(sideWidth) - Number(maxSkyscraperWidth);

    /*if(window.innerWidth > 1776 ){
      maxSkyscraperWidth = 300;
    }
    if(window.innerWidth < 1776 ){
      maxSkyscraperWidth = 260;
    }
    if(window.innerWidth < 1715  ){
      maxSkyscraperWidth = 160;
    }

    if(maxSkyscraperWidth < sideWidth){

       ele.style.display = 'block';
    }else{
       ele.style.display = 'none';
    }*/
     ele.style.left = posX + 'px';


    // Berechnung für tatsächliche Bannergröße => Verursacht Anzeigefehler bei verschiedenen Breiten
    /*if(Number(ele.offsetWidth) > 0) {
      posX = sideWidth - ele.offsetWidth;
    }*/
  }

  positionTop(ele, state) {
    if(state == 'top'){
      ele.style.position = 'absolute';
      ele.style.top = skyscraperWrapperOffset+'px';
    }

    if(state == 'start'){
      ele.style.position = 'fixed';
      ele.style.top = '100px';
    }

    if(state == 'end'){
      ele.style.position = 'absolute';
      ele.style.top = bodyHeight - footerHeight - skyscraperWrapperOffset - skyscraperArray[0].offsetHeight - 48 + 'px';
    }

  }

  docReady(fn) {
    // DOM is already available?
    if (document.readyState === "complete" || document.readyState === "interactive") {
      // call on next available tick
      setTimeout(fn, 2200);
    } else {
      document.addEventListener("DOMContentLoaded", fn);
    }
  }





}
