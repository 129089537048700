import { View } from 'pacto';


const CLASS_COLLAPSED = 'header--is-collapsed';


export class Header extends View {
  constructor(options) {
    super(options);
    this.onIntersect = this.onIntersect.bind(this);
  }

  render() {
    const observer = new IntersectionObserver(
      this.onIntersect,
      { threshold: [0.9] },
    );
    observer.observe(this.el);
  }

  onIntersect(entries) {
    const isCollapsed = !entries.reduce(
      (acc, { isIntersecting }) => acc || isIntersecting,
      false,
    );

    this.el.classList[isCollapsed ? 'add' : 'remove'](CLASS_COLLAPSED);
  }
}
