import { InitializeLazy } from 'pacto';
import { PactoHelper } from '../../../_utils/PactoHelper';

const SELECTOR = '.core-loginpage';

export class Action extends InitializeLazy {
  get settings() {
    return {
      selector: SELECTOR,
    };
  }

  get import() {
    return import(/* webpackChunkName: "core-loginpage" */ './Initialize');
  }

  run() {
    (new PactoHelper()).waitForElm(SELECTOR, document.body, true).then(() => {
      super.run();
    });
  }
}
