import { Initialize } from 'pacto';

import { Navigation as View } from '../views/Navigation';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.navigation',
      namespace: 'navigation:views',
      view: View,
    };
  }
}
