const body = document.body;
const cmtId = 1065;
const toolToken = 'tool_1stparty_kilkaya';
let kilkayaLoaded = false;

export class Kilkaya {

  load() {
    let kilkayaScriptTag = document.createElement('script');
    kilkayaScriptTag.type = "text/javascript";
    kilkayaScriptTag.src = "//cl-eu4.k5a.io/62f60f85045a207a5a2a5c23.js"
    kilkayaScriptTag.async = "true";
    document.head.appendChild(kilkayaScriptTag);

    kilkayaLoaded = true;
  }

  destroy(){
    kilkayaLoaded = false;
  }

  getToolToken() {
    return toolToken;
  }

  getCmtId() {
    return cmtId;
  }

  isLoaded() {
    return kilkayaLoaded;
  }

}


