const cmtId = '1139';
const toolToken = 'tool_cleverpush';
const cleverpushUrl = 'https://static.cleverpush.com/channel/loader/';
let cleverpushFilename = '';
let serviceLoaded = false;

export class Cleverpush {

  load() {

    // SP-1133 check top- and second-level-domain instead of FQDN
    // e.g. if hostname="www2.fnweb.de" the following regex returns ["fnweb.de"]
    const matchArray = window.location.hostname.match(/[^.]*\.[^.]*$/);
    const domain = (matchArray || []).length > 0 ? matchArray[0] : '';

    switch (domain) {
      case "fnweb.de":
        cleverpushFilename = '3d5waiDzoLszt2CkS.js';
        break;
      case "schwetzinger-zeitung.de":
        cleverpushFilename = 'C2YayB9j8cH7x5NSx.js';
        break;
      case "bergstraesser-anzeiger.de":
        cleverpushFilename = 'phMz2WJcptdGxMssr.js';
        break;
      case "mannheimer-morgen.de":
      default:
        cleverpushFilename = 'znTvgF3RpoZbKw9F5.js';
        break;
    }

    let cleverpushScriptNode = document.createElement('script');
    cleverpushScriptNode.type = "text/javascript";
    cleverpushScriptNode.src = cleverpushUrl + cleverpushFilename;
    cleverpushScriptNode.async = "true";
    document.head.appendChild(cleverpushScriptNode);
    serviceLoaded = true;

  }

  destroy(){
    if(serviceLoaded && typeof window.Cleverpush !== 'undefined' ) {
      window.Cleverpush = {};
      serviceLoaded = false;
    }
  }

  isLoaded() {
    return serviceLoaded;
  }

  getCmtId() {
    return cmtId;
  }

  getToolToken() {
    return toolToken;
  }
}
