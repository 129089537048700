import { View } from 'pacto';
import { AccountHelper } from '../../account/service/AccountHelper';
import { AccountData } from '../../account/service/AccountData';
import 'regenerator-runtime/runtime';
import { ConsoleDebug } from '../../../_utils/ConsoleDebug';

const MODIFIER_LOGIN = '--login';
const MODIFIER_ACCOUNT = '--account';
const MODIFIER_LOGOUT = '--logout';
const MODIFIER_HIDDEN = '--hidden';
const ID_CORE_LOGIN_FORM = 'core-login-form';
const ID_CORE_REGISTER_FORM = 'core-register-form';
const ID_LOGOUT_COOKIES = 'logoutCookies';
const CLASS_INTERACTIVE = 'navigation__account__interactive';
const CLASS_INTERACTIVE_ICON = 'navigation__account__interactive--logged-in';
const CTXT_KEY_EVENT_ACCOUNT_DATA_CHANGED = 'event:account_data_changed';
const CTXT_KEY_EVENT_LOGOUT_SUCCESS = 'event:account_logout_successfully';



export class NavigationAccount extends View {

  constructor(options) {
    super(options);

    this.logInstance = new ConsoleDebug('debug-account', 'NavigationAccount');
    this.logInstance.log('core/navigation/views/NavigationAccount.js constructor');

    this.accountData = new AccountData(this.context);
    this.accountHelper = new AccountHelper(options, this);

    this.render = this.render.bind(this);
    this.addNavigationEventListener = this.addNavigationEventListener.bind(this);
    this.buildAccount = this.buildAccount.bind(this);
    this.displayNavLoginLink = this.displayNavLoginLink.bind(this);
    this.logoutClick = this.logoutClick.bind(this);

    this.addNavigationEventListener();

    // Die View soll selbstständig auf Veränderungen der Account-Daten reagieren
    this.context.on(CTXT_KEY_EVENT_ACCOUNT_DATA_CHANGED, (event) => {
      this.logInstance.log('Daten wurden geändert, rufe render() erneut auf', event)
      this.render();
    });
  }

  render() {
    this.logInstance.log('render()');
    this.buildAccount();
    return this;
  }

  /**
   * EventListener für die "Micromodal/Pop-up" Formulare "Login" und "Registrierung"
   */
  addNavigationEventListener () {
    document.getElementById(ID_CORE_LOGIN_FORM)?.addEventListener('submit', this.accountHelper.loginSubmit);
    document.getElementById(ID_CORE_REGISTER_FORM)?.addEventListener('submit', this.accountHelper.registerSubmit);
  }

  async buildAccount() {
    this.logInstance.log('buildAccount()');

    if ( await this.accountData.isLoggedIn() ) {
      let loginButton = this.el.querySelector('.' + CLASS_INTERACTIVE + MODIFIER_LOGIN);
      if ( loginButton != null ) {
        loginButton.style.display = 'none';
      }

      const a = document.createElement('a');
      a.title = 'Zum Ihrem Profil';
      a.href = await this.accountData.getUrlProfile();
      a.classList.add(CLASS_INTERACTIVE);
      a.classList.add(CLASS_INTERACTIVE+MODIFIER_ACCOUNT);
      a.classList.add(CLASS_INTERACTIVE_ICON);
      a.appendChild(document.createTextNode('Hallo, ' + await this.accountData.getFirstname() + ' ' + await this.accountData.getLastname()));

      const childList = Array.from(this.el.children);
      childList.forEach((childEl) => {
        // Das Kind-Element mit der Klasse "navigation__account__interactive--login" nicht löschen, sondern verstecken
        if ( childEl.classList.contains(CLASS_INTERACTIVE+MODIFIER_LOGIN) ) {
          childEl.classList.add(CLASS_INTERACTIVE+MODIFIER_HIDDEN);
        } else {
          this.el.removeChild(childEl);
        }
      });

      // Brgrüßung anfügen
      this.el.appendChild(a);

      if ( this.logInstance.isDebugMode() ) {
        const button = document.createElement('button');
        button.appendChild(document.createTextNode('(abmelden)'));
        button.title = 'Hier abmelden';
        button.type = 'button';
        button.addEventListener('click', this.logoutClick);
        const div = document.createElement('div');
        div.classList.add(CLASS_INTERACTIVE + MODIFIER_LOGOUT);
        div.appendChild(button);
        this.el.appendChild(div);
      }
    } else {
      this.displayNavLoginLink();
    }
  }

  displayNavLoginLink() {
    this.logInstance.log('displayNavLoginLink()');
    let loginButton = this.el.querySelector('.' + CLASS_INTERACTIVE + MODIFIER_LOGIN);
    if ( loginButton != null ) {
        loginButton.style.display = 'block';
        loginButton.classList.remove(CLASS_INTERACTIVE+MODIFIER_HIDDEN);
    }
  }

  async logoutClick() {
    this.logInstance.log('logoutClick()');
    const fetchInput = await this.accountData.getUrlLogout() + `?rand=${Math.random()}`;
    const fetchInit = { method: 'GET', credentials: 'include' };
    window.fetch(fetchInput, fetchInit)
      .then((response) => response.json())
      .then((data) => {
        if (typeof data.logoutCookies === 'string' && data.logoutCookies !== '' )
        {
          // Auf das Event wird z.B. in der Pay View gehört
          this.context.trigger(CTXT_KEY_EVENT_LOGOUT_SUCCESS);

          let logoutCookiesDiv;
          logoutCookiesDiv = document.querySelector('#' + ID_LOGOUT_COOKIES);
          if ( logoutCookiesDiv == null ) {
            logoutCookiesDiv = document.createElement('div');
            logoutCookiesDiv.id = ID_LOGOUT_COOKIES;
            document.body.appendChild(logoutCookiesDiv);
          }
          logoutCookiesDiv.innerHTML = data.logoutCookies;

          this.el.querySelector('.' + CLASS_INTERACTIVE+MODIFIER_LOGOUT)?.remove();
          this.el.querySelector('.' + CLASS_INTERACTIVE+MODIFIER_ACCOUNT)?.remove();

          // Erst wenn alle Logout-Cookies geladen/completed sind, neu laden
          const imgList = logoutCookiesDiv.getElementsByTagName('img');
          this.accountHelper.checkSsoCookieImgLoaded(imgList, () => {
            this.logInstance.log('logoutClick() - Reload now');
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        this.logInstance.log('Error:', error);
      });
  }
}
