import 'what-input';
import 'wicg-inert';
import { Context } from 'pacto';

// Core components:
import { Action as AccountData } from './components/core/account/actions/Initialize';
import { Action as Cmp } from './components/core/cmp/actions/Initialize';
import { Action as Tracking } from './components/core/tracking/actions/Initialize';
import { Action as Header } from './components/core/header/actions/Initialize';
import { Action as Navigation } from './components/core/navigation/actions/Initialize';
import { Action as Modal } from './components/common/modal/actions/InitializeLazy';
import { Action as LoginPageForm } from './components/core/loginpage/actions/InitializeLazy';
import { Action as Fonts } from './components/core/fonts/actions/Initialize';
import { Action as Locations } from './components/core/locations/actions/InitializeLazy';
import { Action as Commercial } from './components/core/commercial/actions/Initialize';
import { Action as Footer } from './components/core/footer/actions/Initialize';
import { Action as Visibility } from './components/core/rule/visibility/actions/Initialize';

// Common components:
import { Action as Pay } from './components/common/pay/actions/Initialize';

// This is required to setup webpack to find all dynamic imports correctly.
// This publicUrl is setup and passed by the critical js include.
// eslint-disable-next-line
__webpack_public_path__ = window.app.publicUrl;

const context = new Context({
  // Enable the event history (log over all triggered events and data). This
  // might be required by components that rely on events that where triggered
  // before they are initialized to catchup the current application state.
  // For more see: https://github.com/schorfES/pacto#context
  history: true,
});

// Attach all initialize actions of components to the "start"-event:
context.actions.add('app:start', [

  // Tracking
  AccountData,
  Cmp,
  Tracking,

  // Core components:
  Header,
  Navigation,
  Locations,
  Commercial,
  Footer,
  Modal,
  Fonts,
  LoginPageForm,
  Visibility,

  // Common components:
  Pay
]);

// do not work context.actions.add('modal-init', [Modal]);

// Execute the application initialization by triggering the "start"-event:
context.trigger('app:start');

//const CONTEXT_KEY_EVENT_ACCOUNT_DATA_CHANGED = 'event:account_data_changed';
//context.actions.add(CONTEXT_KEY_EVENT_ACCOUNT_DATA_CHANGED, [AccountDataChanged]);
//context.trigger(CONTEXT_KEY_EVENT_ACCOUNT_DATA_CHANGED);
