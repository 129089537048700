const cmtId = '42';
const toolToken = 'adprovider_taboola';
const injectContainer = 'taboola-below-article-thumbnails';

let taboolaLoaded = false;
let taboolaCdnRegionParam = 'mannheimer-morgende';

export class Taboola {

  load() {
    window._taboola = window._taboola || [];
    _taboola.push({flush: true});
    switch (window.location.hostname) {
      case "www.mannheimer-morgen.de":
        taboolaCdnRegionParam = 'mannheimer-morgende';
        break;
      case "www.fnweb.de":
        taboolaCdnRegionParam = 'fnwebde';
        break;
      case "www.schwetzinger-zeitung.de":
        taboolaCdnRegionParam = 'schwetzinger-zeitungde';
        break;
      case "www.bergstraesser-anzeiger.de":
        taboolaCdnRegionParam = 'bergstraesser-anzeigerde';
        break;
      default:
        taboolaCdnRegionParam = 'mannheimer-morgende';
        break;
    }

    window._taboola = window._taboola || [];
    window._taboola.push({article: 'auto'});
    !function (e, f, u, i) {
      if (!document.getElementById(i)) {
        e.async = 1;
        e.src = u;
        e.id = i;
        f.parentNode.insertBefore(e, f);
      }
    }(document.createElement('script'),
      document.getElementsByTagName('script')[0],
      '//cdn.taboola.com/libtrc/netpointmedia-' + taboolaCdnRegionParam + '/loader.js',
      'tb_loader_script'
    );
    if (window.performance && typeof window.performance.mark == 'function') {
      window.performance.mark('tbl_ic');
    }

    window._taboola = window._taboola || [];
    window._taboola.push({
      mode: 'alternating-thumbnails-a',
      container: 'taboola-below-article-thumbnails',
      placement: 'Below Article Thumbnails',
      target_type: 'mix'
    });

    taboolaLoaded = true;
  }

  destroy(){
    window.dataLayer = {};
    this.destroyCookie('taboola');
    taboolaLoaded = false;
  }

  isLoaded() {
    return taboolaLoaded;
  }

  getCmtId() {
    return cmtId;
  }

  getToolToken() {
    return toolToken;
  }

  destroyCookie(cookieName) {
      let domain = document.location.hostname.replace(/^[^.]+/g, "");
      let expires = "expires=Thu, 01 Jan 1970 00:00:00 GMT"
      document.cookie = cookieName + "='';" + expires + ";domain="+domain+";path=/;";
      return true;
  }

}
