const cmtId = 's26';
const toolToken = 'tool_ga';
let gaLoaded = false;
let gaId = [];

export class Analytics {

  load() {

    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    //Title
    switch (window.location.hostname) {
      case "www.mannheimer-morgen.de":
        ga('create', 'UA-178661217-1', 'auto');
        ga('set', 'anonymizeIp', true);
        gaId.push('UA-178661217-1');
        break;
      case "www.fnweb.de":
        ga('create', 'UA-178661217-4', 'auto');
        ga('set', 'anonymizeIp', true);
        gaId.push('UA-178661217-4');
       break;
      case "www.schwetzinger-zeitung.de":
        ga('create', 'UA-178661217-2', 'auto');
        ga('set', 'anonymizeIp', true);
        gaId.push('UA-178661217-2');
       break;
      case "www.bergstraesser-anzeiger.de":
        ga('create', 'UA-178661217-3', 'auto');
        ga('set', 'anonymizeIp', true);
        gaId.push('UA-178661217-3');
       break;
      default:
        ga('create', 'UA-178661217-1', 'auto');
        ga('set', 'anonymizeIp', true);
        gaId.push('UA-178661217-1');
       break;
    }

    //Global
    ga('create', 'UA-178661217-6', 'auto', {'name': 'global'});
    gaId.push('UA-178661217-6');
    ga('global.set', 'anonymizeIp', true);

    //Send
    ga('send', 'pageview');
    ga('global.send', 'pageview');

    this.tracker = (...args) => {
      ga('send', args[0], args[1], args[2], args[3]);
    };

    gaId.forEach( (item) => {
      window['ga-disable-'+item] = false;
    });

    setTimeout("ga('send','event','adjusted bounce rate','30 seconds')",30000);
    gaLoaded = true;
  }

  trackingPageView() {
    this.tracker('pageView', window.location.href);
  }

  getCmtId() {
    return cmtId;
  }

  getToolToken() {
    return toolToken;
  }

  isLoaded(){
    return gaLoaded;
  }

  destroy(){
    const cookieNames = ['_ga', '_gid', '_gat', '_AMP_TOKEN']
    window.ga = {};
    gaId.forEach( (item) => {
      window['ga-disable-'+item] = true;
    });
    cookieNames.forEach( (cookieName) => {
      this.destroyCookie(cookieName);
    });
    gaLoaded = false;
  }

  trackEvent(category,action, label) {
    this.tracker('event', category, action, label);
  }

  destroyCookie(cookieName) {
      let domain = document.location.hostname.replace(/^[^.]+/g, "");
      let expires = "expires=Thu, 01 Jan 1970 00:00:00 GMT"
      document.cookie = cookieName + "='';" + expires + ";domain="+domain+";path=/;";
      return true;
  }
}
