import 'regenerator-runtime/runtime';
import { AccountData } from './AccountData';
import { ConsoleDebug } from '../../../_utils/ConsoleDebug';
import { Environment } from "../../../_utils/Environment";
import { RedfactHelper } from "../../../_utils/RedfactHelper";

const ENUM_PAY_LAYER_LOGIN = 2;
const ID_CORE_LOGIN_FORM = 'core-login-form';
const ID_CORE_REGISTER_FORM = 'core-register-form';
const ID_PAYWALL_STEPS_REGISTER_FORM = 'paywall-steps-register-form';
const ID_LOGIN_COOKIES = 'loginCookies';
const CLASS_PAYWALL_HIDDEN = 'paywall--hidden';
const CLASS_CORE_REGISTER_HIDDEN = 'core-register--hidden';
const CTXT_KEY_EVENT_PAY_ANCHOR = 'event:jump_to_pay_anchor';
const CTXT_KEY_EVENT_LOGIN_SUCCESS = 'event:account_login_successfully';

export class AccountHelper {

  constructor(options, originView) {

    this.logInstance = new ConsoleDebug('debug-account', 'Account');
    this.logInstance.log('core/account/service/AccountHelper.js constructor');

    // "Account" ist keine "View" mehr, this.el und this.context werden aber übergangsweise noch benötigt, bis alles umgebaut ist
    this.context = options.context;
		this.el = options.el;

    this.view = originView;
    this.accountData = new AccountData(this.context);
    this.env = new Environment();
    this.redfactHelper = new RedfactHelper();
    this.stopButtonBusyLoop = false;

    this.buttonBusyLoop = this.buttonBusyLoop.bind(this);
    this.loginSubmit = this.loginSubmit.bind(this);
    this.login = this.login.bind(this);
    this.registerSubmit = this.registerSubmit.bind(this);
    this.checkSsoCookieImgLoaded = this.checkSsoCookieImgLoaded.bind(this);
    this.finishCheckSsoCookieImg = this.finishCheckSsoCookieImg.bind(this);
    this.resetError = this.resetError.bind(this);
  }

  loginSubmit(event) {

    event.preventDefault(); // Standard Formular Submit-Handler unterbrechen
    this.formNode = event.target;

    // Fehlermeldungen zurücksetzen
    this.resetError();

    // Bussy Button aktivieren
    this.buttonBusyLoop();

    let fetchInput; let
      fetchInit;

    // Ziel-URL / API-Endpoint für Login Zugangsdaten
    if (this.formNode.getAttribute('data-endpoint') === null) {
      this.buildError();
      return;
    }

    fetchInput = this.formNode.getAttribute('data-endpoint');
    fetchInput += `?rand=${Math.random()}`;
    let formData = new FormData(this.formNode);
    formData = this.appendFormData(formData);

    fetchInit = { method: 'POST', body: formData, credentials: 'include' };

    // Wenn Fractal Web UI Server, dann via GET
    if (window.location.host.match(/^localhost/) !== null) {
      fetchInit = {};
      if (formData.get('login') !== null) {
        fetchInput += `&login=${formData.get('login')}`;
      }
      if (formData.get('pass') !== null) {
        fetchInput += `&pass=${formData.get('pass')}`;
      }
    }

    // fetch ruft normalerweise den redfact customlogin rest service auf, aber bei localhost wird ein GET mit den FormularDaten manuell zusammen gebaut
    window.fetch(fetchInput, fetchInit)
      .then((response) => response.json())
      .then((data) => {
        this.stopButtonBusyLoop = true;
        this.login(data);
      })
      .catch((error) => {
        this.stopButtonBusyLoop = true;
        this.resetFormPassword(this.formNode);
        this.logInstance.log('Error:', error);
      });
  }

  appendFormData(formData) {
    this.logInstance.log('appendFormData()');

    formData.append('puid', this.env.getCurrentPuId());

    // core/loginpage/views/LoginPageForm.js Spezialfall, hier wird die redirect URI für das E-Paper benötigt
    const epaperRedirectUri = this.redfactHelper.getEpaperRedirectUri();
    if ( epaperRedirectUri !== false ) {
      formData.append('redirecturi', epaperRedirectUri);
    }

    return formData;
  }

  login(loginReponseData) {
    this.logInstance.log('login()');
    if (typeof loginReponseData.loginError === 'string' && loginReponseData.loginError !== '') {
      this.resetFormPassword();

      let loginErrorHelpLink = '';
      if (typeof loginReponseData.loginErrorHelpLink === 'string' && loginReponseData.loginErrorHelpLink !== '') {
        loginErrorHelpLink = loginReponseData.loginErrorHelpLink;
      }

      this.buildError(loginReponseData.loginError, loginErrorHelpLink);

    } else if (typeof loginReponseData.loginCookies === 'string'
        && loginReponseData.loginCookies !== '' ) {
      if ( this.payInstance !== undefined ) {
        // @todo tfenrich payInstance gibts hier nicht mehr ggf. via context events lösen
        this.payInstance?.clearLocalStorage(); // Tmp. Paywall-Daten bei Login zurücksetzen
      }

      let loginCookiesDiv = document.querySelector('#' + ID_LOGIN_COOKIES);
      if ( loginCookiesDiv == null ) {
        loginCookiesDiv = document.createElement('div');
        loginCookiesDiv.id = ID_LOGIN_COOKIES;
        document.body.appendChild(loginCookiesDiv);
      }
      loginCookiesDiv.innerHTML = loginReponseData.loginCookies;
      const imgList = loginCookiesDiv.getElementsByTagName('img');
      this.checkSsoCookieImgLoaded(imgList, () => {
        this.finishCheckSsoCookieImg();
      });
      // Nach erfolgreicher Anmeldung die Formularfelder zurücksetzen
      this.resetForm(this.formNode);
    }
  }

  async buttonBusyLoop() {
    this.logInstance.log('call buttonBusyLoop()');
    this.stopButtonBusyLoop = false; // reset stop flag

    // Busy-Merkmal als <span> Tag einfügen in Submit-Button
    let buttonBusy = document.createElement('span');
    buttonBusy.setAttribute('class', 'button__busy button__busy0');
    this.formNode.querySelector('button[type="submit"]')?.appendChild(buttonBusy);

    for (let i = 0; i < 12; i++) {
      // Anmeldung wurde offenbar abeschlossen, Schleife beenden
      if ( this.stopButtonBusyLoop ) {
        break;
      }
      // CSS Klasse ändern um zu wechseln zwischen 1-3 Punkten
      buttonBusy.setAttribute('class', 'button__busy button__busy' + (i%3+1));

      // 0,5s Pause zwischen den Veränderungen
      await new Promise(resolve => setTimeout(resolve, 500));
      this.logInstance.log('buttonBusy i => ' + i + ' % => ' + (i%3+1));
    }

    // Busy-Merkmal in Submit-Button wieder entfernen
    this.formNode.querySelector('button[type="submit"]')?.removeChild(buttonBusy);
  };

  registerSubmit(event) {

    event.preventDefault(); // Standard Formular Submit-Handler unterbrechen
    this.formNode = event.target;
    this.logInstance.log("Hier sollte die Registrierung durchgeführt werden ...");

    // Bussy Button aktivieren
    this.buttonBusyLoop();

    let fetchInput, fetchInit, autologin;

    // Ziel-URL / API-Endpoint für Login Zugangsdaten
    if (this.formNode.getAttribute('data-endpoint') === null) {
      this.buildError();
      return;
    }

    autologin = this.formNode.getAttribute('data-autologin');
    fetchInput = this.formNode.getAttribute('data-endpoint');
    fetchInput += `?rand=${Math.random()}`;
    const formData = new FormData(this.formNode);

    if (typeof window.nfyPu === 'number') {
      formData.append('puid', window.nfyPu);
    }
    if (autologin !== null) {
      formData.append('autologin', autologin);
    }

    fetchInit = { method: 'POST', body: formData, credentials: 'include' };

    // Wenn Fractal Web UI Server, dann via GET
    if (window.location.host.match(/^localhost/) !== null) {
      fetchInit = {};
      if (formData.get('login') !== null) {
        fetchInput += `&login=${formData.get('login')}`;
      }
      if (formData.get('pass') !== null) {
        fetchInput += `&pass=${formData.get('pass')}`;
      }
      if (formData.get('salutation') !== null) {
        fetchInput += `&salutation=${formData.get('salutation')}`;
      }
      if (formData.get('firstname') !== null) {
        fetchInput += `&firstname=${formData.get('firstname')}`;
      }
      if (formData.get('lastname') !== null) {
        fetchInput += `&lastname=${formData.get('lastname')}`;
      }
      if (autologin !== null) {
        fetchInput += `&autologin=${autologin}`;
      }
    }

    window.fetch(fetchInput, fetchInit)
      .then((response) => response.json())
      .then((data) => {
        // success, registerError, isLoggedIn, loginCookies, loginError, loginErrorHelpLink
        if (typeof data.success === 'boolean' && data.success ) {

          // Pop-Up Registrierung
          if ( this.formNode.id == ID_CORE_REGISTER_FORM ) {
            var titleNode = this.formNode.parentNode.parentNode.parentNode.querySelector('#core-register-title');
            if ( titleNode != null ) {
              titleNode.innerHTML = 'Vielen Dank für Ihre Registrierung!'
            }
            // Falls Autologin aktiviert, dann passenden Text der Bestätigungsseite anzeigen
            if ( autologin && data.isLoggedIn ) {
              this.login(data);
              this.formNode.parentNode.parentNode.querySelector('#core-register-step2-autologin-success')?.classList.remove(CLASS_CORE_REGISTER_HIDDEN);
            } else {
              this.formNode.parentNode.parentNode.querySelector('#core-register-step2-autologin-fail')?.classList.remove(CLASS_CORE_REGISTER_HIDDEN);
            }
            // Registrierung Bestätigungsseite anzeigen
            this.formNode.parentNode.classList.add(CLASS_CORE_REGISTER_HIDDEN);
            this.formNode.parentNode.parentNode.querySelector('#core-register-step2')?.classList.remove(CLASS_CORE_REGISTER_HIDDEN);

          // in Paywall-Prozess integrierte Registrierung
          } else if (  this.formNode.id === ID_PAYWALL_STEPS_REGISTER_FORM ) {
            if ( autologin && data.isLoggedIn ) {
              this.login(data);
            } else if ( this.payInstance !== undefined ) {
              // @todo tfenrich payInstance gibts hier nicht mehr, muss mit events im context gehandelt werden
              // falls die Registrierung erfolgreich, aber der autologin fehlschlägt
              this.payInstance?.gotoLayer(ENUM_PAY_LAYER_LOGIN);
              this.payInstance?.fixPaymentLayerBreadcrumb();
            }
          }
          // Nach erfolgreicher Registrierung die Formularfelder zurücksetzen
          this.resetForm(this.formNode);
        } else {
          this.buildError(data.registerError);
        }
      })
      .catch((error) => {
        this.logInstance.log('Error:', error);
      });
  }

  async resetForm (formNode, timeout = 3000) {
    this.logInstance.log("resetForm()");
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => setTimeout(resolve, timeout));
    this.logInstance.log("resetForm() done");
    formNode.reset()
  }

  async resetFormPassword () {
    this.logInstance.log("resetFormPassword()");
    const input = this.formNode.querySelector("input[type='password']");
    if ( input != null ) {
      input.value='';
      input.classList.remove('has-input');
      input.focus();
      input.select();
    }
  }

  async checkSsoCookieImgLoaded(imgList, callback) {
    let completed;

    // check max. 3 seconds, in steps of 250 millis
    this.logInstance.log(`check and await to sso <img... elements are complete... max. 3 seconds, in steps of 250 millis`);
    for (let i = 1; i <= 12; i += 1) {
      completed = true;
      //this.logInstance.log(`${i}. start for-iteration`);

      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 250));
      // eslint-disable-next-line no-loop-func
      Array.from(imgList).forEach((el) => {
        if (!el.complete) {
          completed = false;
          //this.logInstance.log(`Create SSO Cookie failed ... => ${el.src}`);
        }
      });
      if (completed) {
        break;
      }
    }
    callback();
  }

   finishCheckSsoCookieImg() {
    this.logInstance.log('finishCheckSsoCookieImg()');
    if (window.location.hostname.match(/^localhost$/) !== null) {
      this.el.dataset.endpoint = '/__example__/api/account-success-logged-in.json';
    }

    if ( this.formNode.id === ID_CORE_LOGIN_FORM )
    {
      window.MicroModal.close('core-login');
    }

    this.stopButtonBusyLoop = true;

    // Event "Erfolgreich angemeldet" triggern, in der Pay View wird auf das Event gehört und im Pay-Checkout-Flow auf Payment-Layer wechseln
    this.context.trigger(CTXT_KEY_EVENT_LOGIN_SUCCESS, {formNodeId: this.formNode.id});

    // Begrüßung rendern, wenn der Account-Daten-Fetch abgeschlossen ist
    this.accountData.update();
  }

  isArticlePage () {
    return window.location.href.match(/arid[=,]\d*/) !== null;
  }

  isDevArticlePage () {
    return window.location.href.match(/http:\/\/localhost:3000\/components\/preview\/pages-article/) !== null;
  }

  isPaymentPage () {
    return window.location.href.match(/action[=,]checkoutflow/) !== null;
  }

  buildError(explictMessage, errorHelpLinkHtml = '') {
    this.logInstance.log('buildError()');
    let message = 'Es ist ein technisches Problem aufgetreten. Bitte versuchen Sie es später noch einmal. Der Webmaster wurde informiert.';
    if (explictMessage !== undefined && explictMessage !== '') {
      message = explictMessage;
    }
    // [class*="__error"] matched to .core-login__error,.core-loginpage__error,.core-register__error,.paywall-steps__error
    var el = this.formNode.querySelector('[class*="__error"]');
    if ( el !== null ) {

      // Fehlermeldung schreiben
      el.textContent = message;

      // Fehlerlink
      if (errorHelpLinkHtml !== '') {
        var range = document.createRange();
        range.selectNode(document.getElementsByTagName("div").item(0));
        var fragment = range.createContextualFragment("<div class=\"core-login__error-helplink\"><ul><li>"+errorHelpLinkHtml+"</li></ul></div>");
        el.appendChild(fragment);
      }
      el.classList.remove('core-login--hidden', 'core-loginpage--hidden', 'core-register--hidden', CLASS_PAYWALL_HIDDEN);
      this.context.trigger(CTXT_KEY_EVENT_PAY_ANCHOR);
    } else {
      this.logInstance.log('Keine Error-Container gefunden für Fehlermeldung => ' + message);
    }
  }

  resetError() {
    this.logInstance.log('resetError()');
    // [class*="__error"] matched to .core-login__error,.core-loginpage__error,.core-register__error,.paywall-steps__error
    var el = this.formNode.querySelector('[class*="__error"]');
    if ( el !== null ) {
      el.textContent = '';
    }
  }
}
