import { Initialize } from 'pacto';

import { Footer as View } from '../views/Footer';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.footer',
      namespace: 'footer:views',
      view: View,
    };
  }
}
