import { Initialize } from 'pacto';
import { Visibility as View } from '../views/Visibility';
import {PactoHelper} from "../../../../_utils/PactoHelper";

const SELECTOR = '[data-visibility-rule]';

export class Action extends Initialize {
  get settings() {
    return {
      selector: SELECTOR,
      namespace: 'visibility:views',
      view: View,
    };
  }

  run() {
    (new PactoHelper()).waitForElm(SELECTOR).then(() => {
      super.run();
    });
  }
}
