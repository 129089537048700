import { View } from 'pacto';
import {ConsoleDebug} from "../../../../_utils/ConsoleDebug";
import {Environment} from "../../../../_utils/Environment";
import {AccountData} from "../../../account/service/AccountData";

const CTXT_KEY_EVENT_CHANGED = 'event:account_data_changed';
const ATTR_VISIBILITY_RULE = 'data-visibility-rule';
const CLASS_HIDDEN = 'core-rule-visibility--hidden';
const showIfUserHasAccessToEpaper = 'showIfUserHasAccessToEpaper';
const hideIfUserHasAccessToEpaper = 'hideIfUserHasAccessToEpaper';
const showIfUserHasAccessToDigitalPlus = 'showIfUserHasAccessToDigitalPlus';
const hideIfUserHasAccessToDigitalPlus = 'hideIfUserHasAccessToDigitalPlus';
const showIfUserAgentIsMobileDevice = 'showIfUserAgentIsMobileDevice';
const hideIfUserAgentIsMobileDevice = 'hideIfUserAgentIsMobileDevice';

export class Visibility extends View {
  constructor(options) {
    super(options);

    this.applyVisibilityRule = this.applyVisibilityRule.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    this.logInstance = new ConsoleDebug('debug-visibility', 'Visibility');
    this.env = new Environment();
    this.accountData = new AccountData(this.context);
  }

  render() {

    this.applyVisibilityRule();

    this.context.on(CTXT_KEY_EVENT_CHANGED, async (event) => {
      this.logInstance.log('Accountdaten wurden geändert, wahrscheinlich loggin');
      this.applyVisibilityRule();
    });
  }

  /**
   * show this.el
   */
  show() {
    const linkText = this.el.innerText || this.el.textContent
    this.logInstance.log('link \'' + linkText + '\' with ' + ATTR_VISIBILITY_RULE + '=' + this.el.getAttribute(ATTR_VISIBILITY_RULE) + ', remove class '+ CLASS_HIDDEN);
    this.el.classList.remove(CLASS_HIDDEN);
  }

  /**
   * hide this.el
   */
  hide () {
    const linkText = this.el.innerText || this.el.textContent
    this.logInstance.log('link \'' + linkText + '\' with ' + ATTR_VISIBILITY_RULE + '=' + this.el.getAttribute(ATTR_VISIBILITY_RULE) + ', add class ' + CLASS_HIDDEN);
    this.el.classList.add(CLASS_HIDDEN);
  }

  async applyVisibilityRule() {

    this.logInstance.log('applyVisibilityRule() called');

    if ( this.el.getAttribute(ATTR_VISIBILITY_RULE) === null ) {
      this.logInstance.log('this.el.getAttribute(ATTR_VISIBILITY_RULE) is null, stop function applyVisibilityRule()');
      return;
    }

    switch ( this.el.getAttribute(ATTR_VISIBILITY_RULE) ) {
      case showIfUserHasAccessToEpaper:
        await this.accountData.hasAccessToEpaper() ? this.show() : this.hide();
        break;

      case hideIfUserHasAccessToEpaper:
        await this.accountData.hasAccessToEpaper() ? this.hide() : this.show();
        break;

      case showIfUserHasAccessToDigitalPlus:
        await this.accountData.hasAccessToDigitalPlus() ? this.show() : this.hide();
        break;

      case hideIfUserHasAccessToDigitalPlus:
        await this.accountData.hasAccessToDigitalPlus() ? this.hide() : this.show();
        break;

      case showIfUserAgentIsMobileDevice:
        this.env.isMobileDevice() ? this.show() : this.hide();
        break;

      case hideIfUserAgentIsMobileDevice:
        this.env.isMobileDevice() ? this.hide() : this.show();
    }
  }
}
