import {Environment} from "../../../_utils/Environment";
import { ConsoleDebug } from '../../../_utils/ConsoleDebug';

const toolToken = 'tool_1stparty_piano';
let pianoLoaded = false;

export class Piano {

  constructor() {
    this.env = new Environment();
    this.logger = new ConsoleDebug('#debug-piano', 'Piano');
  }

  load() {
    this.logger.log('piano.load() called');
    let pianoAppId = '';
    let environment = '';

    if (this.env.isTestDatabaseEnvironment()) {
      environment = 'sandbox';
      switch (this.env.getHostnameWithoutSubdomain()) {
        case "mannheimer-morgen.de":
          pianoAppId = 'OwRtGt54su'
          break;
        case "fnweb.de":
          pianoAppId = '9rE23QjNsu'
          break;
        case "schwetzinger-zeitung.de":
          pianoAppId = 'RGQ5nOc1su'
          break;
        case "bergstraesser-anzeiger.de":
          pianoAppId = '3FLDo8oSsu'
          break;
      }
    } else {
      environment = 'experience-eu';
      switch (window.location.hostname) {
        case "www.mannheimer-morgen.de":
          pianoAppId = 'xgTkoeZmpe'
          break;
        case "www.fnweb.de":
          pianoAppId = 'zmIxlKxmpe'
          break;
        case "www.schwetzinger-zeitung.de":
          pianoAppId = 'b3rFSwT6pe'
          break;
        case "www.bergstraesser-anzeiger.de":
          pianoAppId = 'JcAWYTTape'
          break;
      }
    }

    if (environment !== '' && pianoAppId !== '') {
      this.logger.log('environment = ' + environment);
      this.logger.log('pianoAppId = ' + pianoAppId);
      let url = "//" + environment + ".piano.io/xbuilder/experience/load?aid=" + pianoAppId

      let pianoScriptTag = document.createElement('script');
      pianoScriptTag.type = "text/javascript";
      pianoScriptTag.src = url;
      pianoScriptTag.async = true;

      //Piano-Script should be first script in head
      let firstScriptTag = document.getElementsByTagName("script")[0];

      if (firstScriptTag) {
        firstScriptTag.parentNode.insertBefore(pianoScriptTag, firstScriptTag);
        pianoLoaded = true;
      } else {
        document.head.appendChild(pianoScriptTag);
      }
      pianoLoaded = true;
      this.logger.log('piano script loaded')
    } else {
      this.logger.log('environment or pianoAppId not set');
      this.logger.log('failed to load piano script');
    }
  }

  destroy() {
    pianoLoaded = false;
  }

  getToolToken() {
    return toolToken;
  }

  isLoaded() {
    return pianoLoaded;
  }

  getToolToken() {
    return toolToken;
  }

}


