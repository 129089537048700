import { View } from 'pacto';

const ATTR_CONTROLS = 'aria-controls';
const ATTR_EXPANDED = 'aria-expanded';
const ATTR_HIDDEN = 'aria-hidden';
const ATTR_PRESSED = 'aria-pressed';
let android_first = true;

export class Toggle extends View {
  constructor(options) {
    super(options);
    const { key, controls } = options;
    if (!controls) {
      throw new Error('Missing "controls" reference for toggle');
    }

    if (!key) {
      throw new Error('Missing "key" for toggle');
    }
    this.onClick = this.onClick.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  render() {
    const { key, controls } = this.options;
    const label = controls.getAttribute('aria-label');

    const el = document.createElement('button');
    el.className = `navigation__toggle navigation__toggle--${key}`;
    el.setAttribute(ATTR_CONTROLS, controls.id);
    el.innerHTML = `
      <span class="navigation__toggle__label">
        <span class="navigation__toggle__text">${label}</span>
      </span>
    `;
    el.addEventListener('click', this.onClick);

    this.el = el;
    this.revalidate();
    window.addEventListener('resize', this.onResize);

    return this;
  }

  revalidate() {
    if (!this.isAndroid() || android_first) {
      window.requestAnimationFrame(() => {
        const {isHidden} = this;
        this.isOpen = isHidden;
        this.el.disabled = isHidden;
        this.el.setAttribute(ATTR_HIDDEN, (isHidden).toString());
        android_first = false;
      });
    }
  }

  get isHidden() {
    return this.el.offsetParent === null;
  }

  get isOpen() {
    const { controls } = this.options;
    return controls.getAttribute(ATTR_HIDDEN) != 'true';
  }

  set isOpen(value) {
    const { el, options } = this;
    const { controls } = options;

    controls.setAttribute(ATTR_HIDDEN, (!value).toString());
    el.setAttribute(ATTR_PRESSED, (value).toString());
    el.setAttribute(ATTR_EXPANDED, (value).toString());

    // if( el.getAttribute(ATTR_PRESSED)){
    //   controls.setAttribute(ATTR_HIDDEN, 'false');
    // } else {
    //   controls.setAttribute(ATTR_HIDDEN, 'true');
    // }

    //controls.inert = !value;

  }

  onClick() {
    const { el, options } = this;
    const { controls } = options;

    document.querySelector('#navigation-topics').style.display = 'block';
    this.isOpen = !this.isOpen;
  }

  onResize() {
    this.revalidate();
  }
  isAndroid(){
    return /(android)/i.test(navigator.userAgent);
  }
}
