import { AccountData } from '../../account/service/AccountData';
import { ConsoleDebug } from '../../../_utils/ConsoleDebug';
import { BineosPlacement } from "../../../common/bineos/views/BineosPlacement";

const toolToken = 'tool_1stparty_bineos';
const CTXT_KEY_EVENT_ACCOUNT_DATA_CHANGED = 'event:account_data_changed';


export class BineosService {

  /**
   * @param {Context} context ist erforderlich um ihn an die Account-Daten Instanz weiterzugeben
   */
  constructor(context) {
    this.context = context;
    this.bineos = false;
    this.logInstance = new ConsoleDebug('debug-bineos', 'BineosService');
    this.logInstance.log('core/tracking/service/BineosService.js constructor');
    this.accountData = new AccountData(this.context);
    this.trackingPageView = this.trackingPageView.bind(this);
    this.handeHookOnLoadPlacement = this.handeHookOnLoadPlacement.bind(this);

    // Selbstständig auf Veränderungen der Account-Daten reagieren und PageView erneut tracken
    this.context.on(CTXT_KEY_EVENT_ACCOUNT_DATA_CHANGED, (event) => {
      this.logInstance.log('Daten wurden geändert, rufe render() erneut auf', event)
      const bineosCfg = this.getBineosCfgVar();
      if ( bineosCfg !== false ) {
        this.trackingPageView(bineosCfg);
      }
    })
  }

  /**
   * triggerd by core/tracking/actions/Initialize.js
   */
  async load() {
    this.logInstance.log('load()');

    // Bineos Klassen Instanziierung erst nach erfolgreichem PromiseOfFetchAccountData starten
    const bineosCfg = this.getBineosCfgVar();
    if ( bineosCfg !== false ) {
      this.loadDynamicBineosLib(bineosCfg.bineosScript)
        .then(() => {

          // Bineos-Klasse instanziieren https://github.com/bohnmedia/bineos#placement-direkt-laden
          this.bineos = new Bineos(bineosCfg.bineosId);
          this.logInstance.log('Bineos-Klasse instanziiert ... new Bineos(' + bineosCfg.bineosId + ')');

          // Bineos-Hook onLoadPlacement vorbereiten https://github.com/bohnmedia/bineos#placement-direkt-laden
          this.logInstance.log('Hook onLoadPlacement vorbereiten');
          this.bineos.on("loadPlacement", this.handeHookOnLoadPlacement);

          // PageView tracken
          this.trackingPageView(bineosCfg);

        }).catch();
    }
  }

  /**
   * Diese Funktion prüft dir Korrektheit der window.bineos_cfg Variable
   * @returns {boolean|string} false = fehlgeschlagen | string e.g. {"bineosId":"74b33b361e","bineosScript":"https:\/\/cdn.dl.mannheimer-morgen.de\/bineosmm\/content\/Bineos_2_0\/bineos.min.js","touchpoint":"startpage","customTopicId":"startseite","articleId":"pa.2031","tags":"","isPaidContent":false,"tshirtsize":"","rtEvent":"","rtKeyword":"","rtPerson":"","rtOrganisation":"","rtLocation":"","rtClassifier1_iab":"","rtClassifier2_topic":"","rtClassifier3":"","content_benefit":""}

   */
  getBineosCfgVar () {
    if ( typeof window.bineos_cfg === 'object'
      && typeof window.bineos_cfg !== null
      && typeof window.bineos_cfg.bineosScript === "string"
      && typeof window.bineos_cfg.bineosId === "string" ) {
      return window.bineos_cfg;
    } else {
      return false;
    }
  }

  handeHookOnLoadPlacement (placement) {

    this.logInstance.log('Bineos-Hook onLoadPlacement getriggert');
    //console.log("Placement", placement);

    if ( typeof placement === "object"
      && typeof placement.data === "object"
      && typeof placement.data.zoneUid === "string"
      && typeof placement.extVar === "object"
      && typeof placement.extVar.redfacttemplate === "string" )
    {
      //this.logInstance.log('extVar.redfacttemplate => ' + placement.extVar.redfacttemplate);

      if ( typeof placement.data.productLoop === "object"
        && placement.data.productLoop.length > 0 )
      {
        //this.logInstance.log(placement.data.productLoop.length + ' Artikel in placement.data.productLoop');

        // Bineos-Platzhalter-Element suchen, View erstellen und Artikel in productLoop iterieren und hinzufügen in View
        // the zoneUid are provided by bineos e.g. <bineos-zone uid="v74i1vb4j4a3"
        let bineosPlacementSelector = 'bineos-zone[uid="' + placement.data.zoneUid + '"]';
        let bineosPlacementEl = document.querySelector(bineosPlacementSelector);
        if ( bineosPlacementEl !== null ) {
          let bineosPlacementView = new BineosPlacement({ el: bineosPlacementEl, zoneUid: placement.data.zoneUid });
          bineosPlacementView.render();

          let i = 0;
          placement.data.productLoop.forEach( entry => {
            i++;
            this.logInstance.log(bineosPlacementSelector + ' ' + i + '. Artikel ID= ' + entry.productId);
            bineosPlacementView.appendItem(entry);
          });

          // Wenn aus dem placement.data.productLoop Items in das bineosPlacementView hinzugefügt wurden, dann die finish() Funktion aufrufen
          if ( i > 0 ) {
            bineosPlacementView.finish();
          }
        }
      }
    }
  }

  async trackingPageView(settings = null, userData = null) {
    this.logInstance.log('trackingPageView called()');

    /*
     * userStatus
     * 1 = „nicht eingeloggt“
     * 2 = „eingeloggt“
     */
    let param = {
      'touchpoint':'',
      'customTopicId':'',
      'articleId':'',
      'tags':'',
      'ivwId':'',
      'userStatus':1,
      'userId':0,
      'permissions':'',
      'isPaidContent':false,
      'tshirtsize':'',
      'rtEvent':'',
      'rtKeyword':'',
      'rtPerson':'',
      'rtOrganisation':'',
      'rtLocation':'',
      'rtClassifier1_iab':'',
      'rtClassifier2_topic':'',
      'rtClassifier3':'',
      'content_benefit':''
    };
    if ( typeof settings === "object"
      && settings !== null
      && typeof this.bineos === "object"
      && typeof this.bineos.set === "function")
    {
      Object.keys(settings).forEach(key => {
        if ( typeof settings[key] !== 'undefined' && typeof param[key] !== 'undefined' ) {
          param[key] = settings[key];
        }
      });

      if ( typeof ivw_cfg == "object" ) {
        param.ivwId = ivw_cfg.desktop.cp;
      }

      param.userStatus = await this.accountData.isLoggedIn() ? 2 : 1;
      param.userId = await this.accountData.getUserId();
      param.permissions = await this.accountData.getBineosPermission();

      // paid_content
      this.bineos.set(param).send();
      this.logInstance.log('bineos sent ' + JSON.stringify(param));
    }
  }

  loadDynamicBineosLib(url) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = resolve;
      script.onerror = reject;
      script.src = url;
      script.async = true;
      script.defer = true;
      document.head.append(script);
    });
  }

  getToolToken() {
    return toolToken;
  }
}
