import { Skyscraper } from './Skyscraper';
import { Billboard } from './Billboard';
import { ConsoleDebug } from "../../../_utils/ConsoleDebug";

const SkyObj = new Skyscraper();
const BillObj = new Billboard();
const conDeb = new ConsoleDebug('debug-commercial-view', 'CommercialView');
const CLASS_AD_SLOTS = '.core-commercial'

export class Commercial {
   render(hasAccess) {
      conDeb.log('Render was called');
     if(!hasAccess) {
       conDeb.log('Start positioning skyscraper and Billboard');
      SkyObj.render();
      BillObj.render();
     } else {
        this.removeAdSlots();
     }

   }

   removeAdSlots () {
     const adSlots = document.querySelectorAll(CLASS_AD_SLOTS);
     conDeb.log('removeAdSlots was called');

     adSlots.forEach((el) => {
       conDeb.log('adSlot', el);
       el.remove();
     })
   }

}
