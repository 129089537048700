import { View } from 'pacto';

import { NavigationAccount } from './NavigationAccount';
import { Toggle } from './Toggle';
import { Weather } from './Weather';

const SELECTOR_ACCOUNT = '#navigation-account';
const SELECTOR_WEATHER = '#navigation-weather';
const SELECTOR_CONTENTS = '#navigation-contents';
const SELECTOR_TOPICS = '#navigation-topics';
const SELECTOR_OPTION = '.navigation__platforms__link';
const SELECTOR_OPTION_2 = '.navigation__topics__list';
const SELECTOR_TOPIC = '.navigation__topic';
const SELECTOR_TOPICS_LIST = '.navigation__topics__list';
const SELECTOR_SUBMENU_BUTTON = '.navigation__topic__submenu__button';
const SELECTOR_TOPLINE = '.navigation__platforms';
const DOMAIN_SELECTOR = '.navigation__domains__list';
let isMobileChecked = false;

export class Navigation extends View {

  constructor(options) {
    super(options);
    this.onClickPlattform = this.onClickPlattform.bind(this);
    this.onClickTopics = this.onClickTopics.bind(this);
    this.onClickDomain = this.onClickDomain.bind(this);
    this.onClickOpenTopicSubmenu = this.onClickOpenTopicSubmenu.bind(this);
    this.revalidate = this.revalidate.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  render() {
    const { context, el } = this;

    const accountEl = el.querySelector(SELECTOR_ACCOUNT);
    const account = new NavigationAccount({ context, el: accountEl });
    account.render();

    const weatherEl = el.querySelector(SELECTOR_WEATHER);
    const weather = new Weather({ context, el: weatherEl });
    weather.render();

    const contentsEl = el.querySelector(SELECTOR_CONTENTS);
    const toggleContents = new Toggle({
      key: 'contents',
      controls: contentsEl,
      context,
    }).render();

    contentsEl.parentElement.insertBefore(toggleContents.el, contentsEl);

    const topicsEl = el.querySelector(SELECTOR_TOPICS);
    const toggleTopics = new Toggle({
      key: 'topics',
      controls: topicsEl,
      context,
    }).render();
    topicsEl.parentElement.insertBefore(toggleTopics.el, topicsEl);


    const submenuButtons = el.querySelectorAll(SELECTOR_SUBMENU_BUTTON);
    let i = 0;
    submenuButtons.forEach((buttonEl) =>  {
      buttonEl.setAttribute('aria-expanded', false);
      buttonEl.setAttribute('aria-pressed', false);
      buttonEl.setAttribute('aria-controls', 'submenu-list-'+i);

      let submenuList = buttonEl.nextElementSibling;
      submenuList.setAttribute('aria-hidden', true);
      submenuList.id = 'submenu-list-'+i;

      buttonEl.addEventListener('click', this.onClickOpenTopicSubmenu);
      i++;

    });


    const items = this.el.querySelectorAll(SELECTOR_OPTION);
    items.forEach((option) => option.addEventListener('click', this.onClickPlattform));
    this.items = items;

    const items2 = this.el.querySelectorAll(SELECTOR_OPTION_2);
    items2.forEach((option) => option.addEventListener('click', this.onClickTopics));
    //console.log(this.onClickTopics);
    this.items2 = items2;

    const items3 = this.el.querySelectorAll(DOMAIN_SELECTOR);
    items3.forEach((option) => option.addEventListener('click', this.onClickDomain));
    //console.log(this.onClickTopics);
    this.items3 = items3;
    window.addEventListener('resize', this.onResize);

    const toplineMob = this.el.querySelector( SELECTOR_TOPICS_LIST + ' + ' + SELECTOR_TOPLINE);
    toplineMob?.classList.add('navigation__platforms--mobile');
  }

  onClickPlattform(event) {

    const { target } = event;
    const { href, title, dataset } = target;
    //event.preventDefault();

    this.context.trigger('top-navigation:click', { target: dataset.target });
  }

  onClickDomain(event) {
    const { target } = event;
    const { href, title, dataset } = target;

    this.context.trigger('domain-navigation:click', { target: dataset.target });
  }

  onClickTopics(event) {
    const { target } = event;
    const { href, title, dataset } = target;
    //event.preventDefault();

    this.context.trigger('topic-navigation:click', { target: dataset.target });
  }

  onClickOpenTopicSubmenu(event) {
    const { target } = event;
    const { href, title, dataset } = target;
    let submenuList = target.nextElementSibling;
    let isClosed = target.getAttribute('aria-pressed');



    if(isClosed == 'true' ) {
      submenuList.setAttribute('aria-hidden', true);
      target.setAttribute('aria-pressed', false);
      target.setAttribute('aria-expanded', false);
    } else {
      submenuList.setAttribute('aria-hidden', false);
      target.setAttribute('aria-pressed', true);
      target.setAttribute('aria-expanded', true);
    }

  }

  revalidate(isHidden) {
    const submenuButtons = this.el.querySelectorAll(SELECTOR_SUBMENU_BUTTON);
    submenuButtons.forEach((buttonEl) =>  {
      buttonEl.setAttribute('aria-expanded', isHidden);
      buttonEl.setAttribute('aria-pressed', isHidden);
      let submenuList = buttonEl.nextElementSibling;
      submenuList.setAttribute('aria-hidden', !isHidden);
    });
  }

  onResize() {
    // if(window.innerWidth > 768) {
    //   isMobileChecked = false;
    //
    // } else {
    //   if(!isMobileChecked) {
    //     isMobileChecked = true;
    //     this.revalidate(true);
    //   }
    //
    // }
    this.revalidate(false);

  }

}
