export class Action {
  run() {
    // @TODO: Add flag here to detect if code is in development or production
    // environment to prevent pageview calls during development.

    if (document.readyState === 'complete') {
      this.track();
    } else {
      window.addEventListener('load', () => this.track(), { once: true });
    }
  }

  track() {
    // Add pageview call for "austeratext" font:
    window.fetch('https://hello.myfonts.net/count/3c16f7')
      .catch(() => { /* @TODO: Add error tracker here or ignore error */ });
  }
}
