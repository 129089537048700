import { View } from 'pacto';

// @TODO: Use BEM helper here:
const SELECTOR_ITEM = '.common-accordion__item';
const CLASS_ACTIVE = 'common-accordion__item--active';
const CLASS_PANEL =  'common-accordion__item__panel';
const doc = document;

export class Accordion extends View {
  constructor(options) {
    super(options);
    this.onClick = this.onClick.bind(this);
    this.onIntersect = this.onIntersect.bind(this);
  }

  render() {
    const observer = new IntersectionObserver(
      this.onIntersect,
      { threshold: [0, 0.25, 0.5, 0.75, 1] },
    );

    const items = Array.from(this.el.querySelectorAll(SELECTOR_ITEM));

    const sections = items.map((item) => {
      const selector = item.getAttribute('id');

      const section = doc.querySelector(`#${selector}`);

      if (!section) {
        return null;
      }

      item.addEventListener('click', this.onClick);

      observer.observe(section);
      return section;
    }).filter((section) => section !== null);

    this.observer = observer;
    this.items = items;
    this.sections = sections;
  }

  destroy() {
    if (!this.observer) {
      return;
    }

    this.observer.disconnect();
    this.observer = null;
    this.items.forEach((item) => item.removeEventListener('click', this.onClick));
    this.items = null;
    this.sections = null;
  }

    onClick(event) {
      let clickedTab;

      if(event.target.nodeName == 'LABEL'){
        clickedTab = event.target.parentNode;
      }else{
        clickedTab = event.target;
      }

      const activeItems = Array.from(this.el.querySelectorAll('.' + CLASS_ACTIVE));
       activeItems.forEach(item => {
       /* item.classList.remove(CLASS_ACTIVE); */
      })

      const section = doc.querySelector(`#${clickedTab.getAttribute('id')}`);
      if (!section) {
        return;
      }
      clickedTab.classList.toggle(CLASS_ACTIVE);
      event.preventDefault();
    }

  onIntersect() {

  }
}
