import { View } from 'pacto';


const CLASS_TITLE = 'navigation__weather__title';
const CLASS_DATA = 'navigation__weather__data';
const CLASS_LABEL = 'navigation__weather__label';
const CLASS_ICON = 'navigation__weather__icon';


export class Weather extends View {
  constructor(options) {
    super(options);
    this.onLocationChange = this.onLocationChange.bind(this);
  }

  render() {
    //this.fetch();
    this.context.on('locations:change', this.onLocationChange);
    return this;
  }

  fetch() {
    const { endpoint } = this.el.dataset;
    window.fetch(endpoint)
      .then((response) => response.json())
      .then(({ condition, temperature }) => {
        this.el.innerHTML = `
          <dl>
            <dt class="${CLASS_TITLE}">
              Aktuelle Temperatur
            </dt>
            <dd class="${CLASS_DATA}">
              ${temperature.current}°C
            </dd>

            <dt class="${CLASS_TITLE}">
              Bedingung
            </dt>
            <dd class="${CLASS_DATA}">
              <span class="${CLASS_LABEL}">${condition.title}</span>
              <img
                class="${CLASS_ICON}"
                src="${condition.icon}"
                alt="${condition.title}"
                title="${condition.title}"
                role="presentation"
              />
            </dd>
          </dl>
        `;
      })
      .catch(() => {
        this.el.innerHTML = '';
      });
  }

  onLocationChange() {
    this.fetch();
  }
}
