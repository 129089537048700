const cmtId = '730';

export class IvwUpdate {

  load(ivwSettings) {
    const stubJsPath = '/iomm/latest/bootstrap/stub.js';
    const bundleJsPath = '/iomm/latest/manager/base/es5/bundle.js';

    var hostStr = window.location.hostname;
    var hostStrNew = hostStr.replace(/^[\w]+\./, '');

    let dn = this.getIvwDomainData(hostStrNew);
    let ivwSubDomain = dn + '.' + hostStrNew;

    this.loadDynamicIvwLib('https://' + ivwSubDomain + stubJsPath)
      .then(() => {
        if(this.trackingPageView(ivwSettings, ivwSubDomain)){
          //console.log('[INFO]: IVWUpdate Setup is live');
          this.loadDynamicIvwLib('https://' + ivwSubDomain + bundleJsPath, true, {'data-cmp-ab': 2});
        }
      }).catch((error) => {
        console.info('[IVW]: IVW Script wurde nicht geladen.');
    });
  }

  getIvwDomainData(hostStrNew) {
    // data-83d91ea519 Morgenweb Desktop
    // data-f62d7c5cdb Morgenweb Mobile
    let dn = '';
    let device = this.getDeviceWidth();

    if(device == 'mobile'){
       dn = 'data-f62d7c5cdb';
    } else {
       dn = 'data-83d91ea519';
    }

    return dn;
  }

  trackingPageView(ivwSettings, domain) {
    // console.log('[INFO]: IVW trigger was called …');
    let iomDeviceParam;
    let device = this.getDeviceWidth();
    //console.log('[INFO]', ivwSettings);
    //console.log('[INFO]', ivwSettings[device].cp);
      window.IOMm('configure', {st: ivwSettings[device].st, dn: domain, mh:5}); // Configure IOMm
      window.IOMm('pageview', {cp: ivwSettings[device].cp}); // Count pageview
      return true;
  }

  loadDynamicIvwLib(url, appendOnBody = false, addParam = {}) {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = resolve;
      script.onerror = reject;
      script.src = url;
      script.setAttribute('data-cmp-ab', '2');

      if(!appendOnBody){
        document.head.append(script);
      }else{
        document.body.append(script);
      }
    });
  }

  getDeviceWidth() {
    if (window.innerWidth >= 768) {
      return 'desktop';
    }
    return 'mobile';
  }

}
