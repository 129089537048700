import { Initialize } from 'pacto';
import { Pay as View } from '../views/Pay';
import { PactoHelper } from '../../../_utils/PactoHelper';
import { Environment } from '../../../_utils/Environment';

const SELECTOR = '#paywall, #pay-standalone-page';

export class Action extends Initialize {
  get settings() {
    return {
      selector: SELECTOR,
      namespace: 'pay:views',
      view: View,
    };
  }

  run() {
    const env = new Environment();
    if ( env.isArticleDetailPage() || env.isPayStandalonePage() ) {
      (new PactoHelper()).waitForElm(SELECTOR).then(() => {
        super.run();
      });
    }
  }
}
