const smartbannerUrl = '/pu_mm_2020/scripts/smartbanner.js';
let serviceLoaded = false;

export class Smartbanner {

  load() {

    let smartbannerScriptNode = document.createElement('script');
    smartbannerScriptNode.type = "text/javascript";
    smartbannerScriptNode.src = smartbannerUrl;
    smartbannerScriptNode.async = "true";
    document.head.appendChild(smartbannerScriptNode);
    serviceLoaded = true;

  }

  destroy(){

  }

  isLoaded() {
    return serviceLoaded;
  }
}
