import { View } from 'pacto';
import { ConsoleDebug } from '../../../_utils/ConsoleDebug';
import { Environment } from '../../../_utils/Environment'
import { PactoHelper } from '../../../_utils/PactoHelper';
import { RedfactHelper } from '../../../_utils/RedfactHelper';
import { AccountData } from '../../../core/account/service/AccountData';
import { AccountHelper } from '../../../core/account/service/AccountHelper';


const ENUM_PAY_LAYER_INFO = 0;
const ENUM_PAY_LAYER_REGISTER = 1;
const ENUM_PAY_LAYER_LOGIN = 2;
const ENUM_PAY_LAYER_PAYMENT = 3;
//const ENUM_PAY_LAYER_QUOTA = 4;
const ID_PAY_STANDALONE_PAGE = 'pay-standalone-page';
const ID_PAY_CHECKOUTFLOW = 'common-pay-checkoutflow';
const ID_PAY_LAYER_INFO = 'paywall-info';
const ID_PAY_LAYER_REGISTER = 'paywall-steps-register';
const ID_PAY_LAYER_LOGIN = 'paywall-steps-login';
const ID_PAY_LAYER_PAYMENT = 'paywall-steps-payment';
const ID_PAY_LAYER_QUOTA = 'paywall-quota';
const ID_PAY_LAYER_LOGIN_INTRO = 'paywall-steps-login-intro';
const ID_PAY_LAYER_LOGIN_INTRO_SKIPPED_REGISTER = 'paywall-steps-login-intro-skipped-register';
const ID_PAY_LAYER_LOGIN_FOOTER = 'paywall-steps-login-footer';
const ID_PAY_LAYER_LOGIN_FOOTER_SKIPPED_REGISTER = 'paywall-steps-login-footer-skipped-register';
const ID_PAY_STEPS_LOGIN_FORM = 'paywall-steps-login-form';
const ID_PAY_STEPS_REGISTER_FORM = 'paywall-steps-register-form';
const ID_PLENIGO_CHECKOUT = 'plenigoCheckout';
const ID_PLENIGO_SDK = 'plenigoSdk';
const ID_CORE_LOGIN_FORM = 'core-login-form';
const ID_CORE_REGISTER_FORM = 'core-register-form';
const CLASS_PAYWALL_HIDDEN = 'paywall--hidden';
const CLASS_BREADCRUMB_ACTIVE = 'paywall-steps__breadcrumb--active';
const LS_KEY_SUBSCRIPTION_PURCHASE_TIME = 'subscriptionPurchaseTime';
const LS_KEY_SUBSCRIPTION_PURCHASE_LOCATION_HREF = 'subscriptionPurchaseLocationHref';
const LS_KEY_SUBSCRIPTION_PURCHASE_OFFER_ID = 'subscriptionPurchaseOfferId';
const LS_KEY_RF_TRANSACTION_ID = 'rfTransactionId';
const SUBSCRIPTION_PURCHASE_TTL = 600;
const ERROR_MESSAGE = 'Es ist ein technisches Problem aufgetreten. Bitte versuchen Sie es später noch einmal. Der Webmaster wurde informiert.';
const CTXT_KEY_EVENT_PAY_ANCHOR = 'event:jump_to_pay_anchor';
const CTXT_KEY_EVENT_LOGOUT_SUCCESS = 'event:account_logout_successfully';
const CTXT_KEY_EVENT_LOGIN_SUCCESS = 'event:account_login_successfully';

export class Pay extends View {

  constructor(options) {
    super(options);

    this.env = new Environment();
    this.logInstance = new ConsoleDebug('debug-account', 'Pay');
    this.logInstance.log('common/pay/views/Pay.js constructor');

    this.accountData = new AccountData(this.context);
    this.accountHelper = new AccountHelper(options, this);
    this.redfactHelper = new RedfactHelper();

    this.determineStartLayer = this.determineStartLayer.bind(this);
    this.renderLayer = this.renderLayer.bind(this);
    this.setLayer = this.setLayer.bind(this);
    this.showLayer = this.showLayer.bind(this);
    this.hideLayer = this.hideLayer.bind(this);
    this.nextLayer = this.nextLayer.bind(this);
    this.gotoLayerLoginSkipRegister = this.gotoLayerLoginSkipRegister.bind(this);
    this.gotoLayerRegister = this.gotoLayerRegister.bind(this);
    this.startPurchase = this.startPurchase.bind(this);
    this.preparePurchase = this.preparePurchase.bind(this);
    this.purchaseSuccess = this.purchaseSuccess.bind(this);
    this.purchaseExists = this.purchaseExists.bind(this);
    this.renderPlenigoIFrame = this.renderPlenigoIFrame.bind(this);
    this.loadPlenigoSdk = this.loadPlenigoSdk.bind(this);
    this.showTextDependOnLoggedIn = this.showTextDependOnLoggedIn.bind(this);
    this.showIntroFooter = this.showIntroFooter.bind(this);
    this.buildError = this.buildError.bind(this);
    this.quota = this.quota.bind(this);
    this.getRemainQuota = this.getRemainQuota.bind(this);
    this.toAnchor = this.toAnchor.bind(this);
    this.handleMSIE = this.handleMSIE.bind(this);
    this.waitAndAddEventListener = this.waitAndAddEventListener.bind(this);

    // Konfiguration
    this.layerList = [ID_PAY_LAYER_INFO, ID_PAY_LAYER_REGISTER, ID_PAY_LAYER_LOGIN, ID_PAY_LAYER_PAYMENT, ID_PAY_LAYER_QUOTA];

    this.rfTransactionId = '';
    this.plenigoPurchaseId = '';
    this.skipRegister = false;
    this.preparePurchaseCalled = false;
    this.plenigoOfferId = '';
    this.plenigoSDKloadPromise = null;

    document.addEventListener("plenigo.PurchaseSuccess", this.purchaseSuccess);

    this.waitAndAddEventListener('#button-paywall-quota', 'click', this.quota);
    this.waitAndAddEventListener('#goto-paywall-steps-login', 'click', this.gotoLayerLoginSkipRegister);
    this.waitAndAddEventListener('#goto-paywall-steps-register', 'click', this.gotoLayerRegister);
    this.waitAndAddEventListener('#' + ID_PAY_STEPS_LOGIN_FORM, 'submit', this.accountHelper.loginSubmit);
    this.waitAndAddEventListener('#' + ID_PAY_STEPS_REGISTER_FORM, 'submit', this.accountHelper.registerSubmit);

    (new PactoHelper()).waitForElm('#paywall-info .common-accordion__orderbutton', this.el, true, true).then(() => {
      //this.logInstance.log("this.el.querySelectorAll('#paywall-info .common-accordion__orderbutton')", this.el.querySelectorAll('#paywall-info .common-accordion__orderbutton'));
      this.el.querySelectorAll('#paywall-info .common-accordion__orderbutton')?.forEach((node) => {
        node.addEventListener('click', this.startPurchase);
      });
    });

    // Die View soll selbstständig zum Pay-Anker springen, wenn der Event getriggert wird
    this.context.on(CTXT_KEY_EVENT_PAY_ANCHOR, (event) => {
      this.logInstance.log('Auf Event \'' + CTXT_KEY_EVENT_PAY_ANCHOR + '\' reagiert, this.toAnchor() aufrufen', event)
      this.toAnchor();
    });

    // Auf Event "Erfolgreich abgemeldet" hören, in die Pay-View spezifischen LocalStorage entfernen
    this.context.on(CTXT_KEY_EVENT_LOGOUT_SUCCESS, (event) => {
      this.logInstance.log('Auf Event \'' + CTXT_KEY_EVENT_LOGOUT_SUCCESS + '\' reagiert, this.clearLocalStorage() aufrufen', event)
      this.clearLocalStorage();
    });

    // Auf Event "Erfolgreich angemeldet" hören, in der Pay View im Pay-Checkout-Flow auf Payment-Layer wechseln
    this.context.on(CTXT_KEY_EVENT_LOGIN_SUCCESS, (event) => {
      this.logInstance.log('Auf Event \'' + CTXT_KEY_EVENT_LOGIN_SUCCESS + '\' reagiert, ggf. this.setLayer(ENUM_PAY_LAYER_PAYMENT) aufrufen', event);
      if ( event.data.formNodeId !== undefined ) {

        // Paywall Schritt "Bestellung abschließen" rendern
        if ( event.data.formNodeId === ID_PAY_STEPS_LOGIN_FORM || event.data.formNodeId === ID_PAY_STEPS_REGISTER_FORM ) {
          this.setLayer(ENUM_PAY_LAYER_PAYMENT);
          this.render();
        }

        // Nach Anmeldung in den Formularen core-login oder core-register auf Artikel- oder Payment-Seite einen reload durchfuehren, ggf. darf der Nutzer durch die Paywall (diese Logik ist redFACT serverseitig)
        if ( event.data.formNodeId === ID_CORE_LOGIN_FORM
          || event.data.formNodeId === ID_CORE_REGISTER_FORM
          && ( this.env.isArticleDetailPage()
            || this.env.isPayStandalonePage() )) {
            window.location.reload();
        }
      }
    });
  }

  waitAndAddEventListener (selectors, type, callback) {
    (new PactoHelper()).waitForElm(selectors, this.el, true, true).then(() => {
      this.el.querySelector(selectors)?.addEventListener(type, callback);
    });
  }

  render() {
    this.logInstance.log('render() called');
    this.renderLayer();
  }

  /**
   * Diese Funktion ermittelt den Start-Layer
   */
  async determineStartLayer () {
    this.logInstance.log('determineStartLayer() called');

    if ( window.location.hash === '#goto-paywall-steps-login' ) {
      // SP-1068 Für die Kündigerrückgewinnung sollten Deeplinks mit dem location.hash === '#goto-paywall-steps-login' direkt auf den Login-Layer springen
      this.selectedLayer = ENUM_PAY_LAYER_LOGIN;
      this.skipRegister = true;
    } else if ( this.el.id === ID_PAY_STANDALONE_PAGE ) {
      // Standardverhalten auf Pay-Standalone-Seite startet mit Register-Layer "paywall-steps-register" (teil des Checkoutflow)
      this.selectedLayer = ENUM_PAY_LAYER_REGISTER;
    } else {
      // Standardverhalten auf Artikel-Paywall startet mit Info-Layer "paywall-info"
      this.selectedLayer = ENUM_PAY_LAYER_INFO;
    }

    // Wenn ein Nutzer eingeloggt ist, überprüfen ob ...
    // es ein PayPal Rückkehrer ist
    // oder auf einer Artikeldetailseite eine kürzliche Bestellvorgang wiederhergestellt werden kann
    // oder der selektierte Layer größer als der Info-Layer ist
    if ( await this.accountData.isLoggedIn() ) {
      if ( this.paypalReturnee() ) {
        this.logInstance.log('determine selectedLayer = ENUM_PAY_LAYER_PAYMENT = ' + ENUM_PAY_LAYER_PAYMENT + '; because isLoggedIn and this.paypalReturnee() return true');
        this.selectedLayer = ENUM_PAY_LAYER_PAYMENT;
      } else if ( this.env.isArticleDetailPage() && this.purchaseExists() ) {
        this.logInstance.log('determine selectedLayer = ENUM_PAY_LAYER_PAYMENT = ' + ENUM_PAY_LAYER_PAYMENT + '; because isLoggedIn and this.env.isArticleDetailPage() and this.purchaseExists() return true');
        this.selectedLayer = ENUM_PAY_LAYER_PAYMENT;
      } else if ( this.selectedLayer > ENUM_PAY_LAYER_INFO ) {
        this.logInstance.log('determine selectedLayer = ENUM_PAY_LAYER_PAYMENT = ' + ENUM_PAY_LAYER_PAYMENT + '; because isLoggedIn and this.selectedLayer > ENUM_PAY_LAYER_INFO');
        this.selectedLayer = ENUM_PAY_LAYER_PAYMENT;
      }
    }

    this.logInstance.log('determine selectedLayer = ' + this.selectedLayer);
    this.selectedLayerNode = this.el.querySelector('#'+this.layerList[this.selectedLayer]);
  }

  setLayer( layerEnum = false ) {
    this.logInstance.log('setLayer('+layerEnum+') called');
    this.selectedLayer = layerEnum;
    this.selectedLayerNode = this.el.querySelector('#'+this.layerList[this.selectedLayer]);
  }

  gotoLayer( layerEnum = false ) {
    this.logInstance.log('gotoLayer('+layerEnum+') called');
    this.setLayer(layerEnum);
    this.renderLayer();
  }

  gotoLayerLoginSkipRegister() {
    this.logInstance.log('gotoLayerLoginSkipRegister() called');
    this.skipRegister = true;
    this.nextLayer();
  }

  gotoLayerRegister() {
    this.logInstance.log('gotoLayerRegister() called');
    if ( this.env.isMSIE() ) {
      this.handleMSIE();
    } else {
      this.gotoLayer(ENUM_PAY_LAYER_REGISTER);
    }
  }

  async startPurchase(event) {
    this.logInstance.log('startPurchase() called');
    // event PointerEvent{isTrusted: true, pointerId: 1, width: 1, height: 1, pressure: 0, …}
    // event.target e.g. <button type="button" value="O_5VO5B1CFOH86MLNL4H" class="button__button common-accordion__orderbutton">Jetzt bestellen</button>
    // event.target.value e.g. O_5VO5B1CFOH86MLNL4H
    this.plenigoOfferId = event.target.value;
    this.logInstance.log('this.plenigoOfferId = ' + event.target.value);

    // Wenn ein Nutzer eingeloggt ist, Payment-Layer anzeigen
    if ( await this.accountData.isLoggedIn() ) {
      this.gotoLayer(ENUM_PAY_LAYER_PAYMENT);
    } else {
      // Ansonsten den Registrierung-Layer anzeigen
      this.gotoLayerRegister();
    }
  }

  /**
   * Fehlermeldung für IE anzeigen inkl. Effekt
   */
  handleMSIE() {
    this.toAnchor();
    var errNode = this.selectedLayerNode.querySelector('#paywall-info-ie11');
    errNode?.classList.remove(CLASS_PAYWALL_HIDDEN);
    errNode?.classList.add('paywall__error--effect');
    setTimeout(function() {
        errNode?.classList.remove('paywall__error--effect');
    }, 2000);
  }

  nextLayer() {
    this.selectedLayer++;
    this.selectedLayerNode = this.el.querySelector('#'+this.layerList[this.selectedLayer]);
    this.renderLayer();
  }

  async renderLayer(s) {
    this.logInstance.log('renderLayer() called');
    this.logInstance.log('selectedLayer = ' + this.selectedLayer);

    // Wenn noch kein Layer ausgewählt wurde, dann den passenden ermitteln
    if ( this.selectedLayer === undefined ) {
      await this.determineStartLayer();
    }

    // Texte anzeigen abhängig von Login-Status z.B. für kostenfreie Artikel Freischaltung
    this.showTextDependOnLoggedIn();

    // Im "Login"-Layer situationsabhängige Intro- & Footer-Text anzeigen
    if ( this.selectedLayer === ENUM_PAY_LAYER_LOGIN ) {
      this.showIntroFooter();
    }

    // Zuerst die nicht selektierten Layer verstecken
    this.layerList.forEach((layerDomId, layerEnum) => {
      if (layerEnum !== this.selectedLayer) {
        this.hideLayer(layerDomId);
      }
    });

    // Danach die selektierten Layer anzeigen
    const layerDomId = this.layerList[this.selectedLayer];
    await this.showLayer(layerDomId);

    // Mit Ausnahme dem 1. Info- & Quota-Layer einen HMTL-Anker aktivieren
    if ( layerDomId !== ID_PAY_LAYER_INFO
      && layerDomId !== ID_PAY_LAYER_QUOTA
      && ( layerDomId !== ID_PAY_LAYER_REGISTER || !this.env.isPayStandalonePage() ) ) {
      this.toAnchor();
    }

    // Plenigo SDK laden, wenn möglich schon früher z.B. während das Registrierungsformular angezeigt wird
    if ( this.selectedLayer === ENUM_PAY_LAYER_REGISTER
      || this.selectedLayer === ENUM_PAY_LAYER_PAYMENT
      && this.plenigoSDKloadPromise === null ) {
      this.plenigoSDKloadPromise = this.loadPlenigoSdk();
    }

    if ( this.selectedLayer === ENUM_PAY_LAYER_PAYMENT
      && PactoHelper.isPromise(this.plenigoSDKloadPromise) )
    {
      this.plenigoSDKloadPromise.then(() => {
        // Wenn ein Nutzer mit einem GET-Parameter "pci=" in der Adressleiste zurückkehrt, hat wahrscheinlich ein Paypal Kauf stattgefunden. Dann sollte die Plenigo Sitzung wiederhergestellt werden d.h. es darf keine neue purchaseId generiert werden
        if ( this.paypalReturnee() ) {
          this.renderPlenigoIFrame();
        } else {
          this.preparePurchase();
        }
      });
    }
  }

  async showTextDependOnLoggedIn () {
    this.logInstance.log('showTextDependOnLoggedIn() called');
    if ( this.selectedLayer === ENUM_PAY_LAYER_INFO ) {
      var removeSelector = '.paywall--showisntloggedin';
      var addSelector = '.paywall--showisloggedin';
      if ( await this.accountData.isLoggedIn() ) {
        removeSelector = '.paywall--showisloggedin';
        addSelector = '.paywall--showisntloggedin';
        // Sonderfall Plus-Artikel: Wenn angemeldet und "Info"-Layer anzeigt wird, dann auch "Plus-Artikel"-Layer anzeigen
        if ( this.env.isArticleDetailPage() || this.env.isDevArticleDetailPage() ) {
          const remainQuota = await this.getRemainQuota();

          // Platzhalter ersetzten durch personalisierte Begrüßung#
          const accountName = await this.accountData.getFirstname() + ' ' + await this.accountData.getLastname();
          const nodeList = this.el.querySelectorAll('.paywall-name-placeholder');
          if ( nodeList != null ) {
            nodeList.forEach(placeholder => {
              const newText = 'Guten Tag ' + accountName + ', ' + placeholder.innerText.toLowerCase();
              this.logInstance.log(`ersetze '${placeholder.innerText}' durch '${newText}'`);
              placeholder.innerText = newText;
            });
          }

          if ( remainQuota > 0 ) {
            this.el.querySelector('#paywall-quota-status').innerHTML = (remainQuota-1);
            this.el.querySelector('#paywall-quota-available')?.classList.remove(CLASS_PAYWALL_HIDDEN);
          } else {
            this.el.querySelector('#paywall-quota-exhausted')?.classList.remove(CLASS_PAYWALL_HIDDEN);
          }
          this.showLayer(ID_PAY_LAYER_QUOTA);

          // @todo fenrich handle error case maybe with try catch
            /*
          } else {
            const layerQuota = this.el.querySelector('#'+ID_PAY_LAYER_QUOTA);
            if ( layerQuota !== null ) {
              this.buildError(null, 'quotastatus abfrage fehlgeschlagen', layerQuota);
            }
          }*/
        }
      }
      this.el.querySelectorAll(removeSelector)?.forEach((node) => {
        node.classList.remove(CLASS_PAYWALL_HIDDEN);
      });
      this.el.querySelectorAll(addSelector)?.forEach((node) => {
        node.classList.add(CLASS_PAYWALL_HIDDEN);
      });
    }
  }

  // Sonderfall: Vor dem "Login"-Layer anzeigen, ggf. den passenden Intro- & Footer-Text anzeigen
  showIntroFooter() {
    if ( this.selectedLayerNode != null ) {
      var introNode = this.selectedLayerNode.querySelector('#'+ID_PAY_LAYER_LOGIN_INTRO);
      var introNodeSkipRegister = this.selectedLayerNode.querySelector('#'+ID_PAY_LAYER_LOGIN_INTRO_SKIPPED_REGISTER);
      var footerNode = this.selectedLayerNode.querySelector('#'+ID_PAY_LAYER_LOGIN_FOOTER);
      var footerNodeSkipRegister = this.selectedLayerNode.querySelector('#'+ID_PAY_LAYER_LOGIN_FOOTER_SKIPPED_REGISTER);

      if ( this.skipRegister ) {
        introNodeSkipRegister?.classList.remove(CLASS_PAYWALL_HIDDEN);
        footerNodeSkipRegister?.classList.remove(CLASS_PAYWALL_HIDDEN);
        introNode?.classList.add(CLASS_PAYWALL_HIDDEN);
        footerNode?.classList.add(CLASS_PAYWALL_HIDDEN);
      } else {
        introNode?.classList.remove(CLASS_PAYWALL_HIDDEN);
        footerNode?.classList.remove(CLASS_PAYWALL_HIDDEN);
        introNodeSkipRegister?.classList.add(CLASS_PAYWALL_HIDDEN);
        footerNodeSkipRegister?.classList.add(CLASS_PAYWALL_HIDDEN);
      }
    }
    this.skipRegister = false;
  }

  async showLayer(layerDomId) {
    this.logInstance.log('showLayer('+layerDomId+') called');
    await (new PactoHelper()).waitForElm('#'+layerDomId);
    this.el.querySelector('#'+layerDomId)?.classList.remove(CLASS_PAYWALL_HIDDEN);
  }

  hideLayer(layerDomId) {
    this.el.querySelector('#'+layerDomId)?.classList.add(CLASS_PAYWALL_HIDDEN);
  }

  isLayerVisible(layerDomId) {
    this.logInstance.log('isLayerVisible('+layerDomId+') called');
    return this.el.querySelector('#'+layerDomId)?.offsetParent !== null;
  }

  /*
   * Wenn ein Nutzer mit einem GET-Parameter "pci=" in der Adressleiste zurückkehrt,
   * hat wahrscheinlich ein Paypal Kauf stattgefunden.
   *
   * Dann sollte die Plenigo Sitzung wiederhergestellt werden
   * d.h. es darf keine neue purchaseId generiert werden
   */
  paypalReturnee () {
    this.logInstance.log('paypalReturnee() called');
    if ( window.location.search.match("(pci=)") !== null ) {
      this.logInstance.log('Parameter deutet auf Payment-Rückkehrer (z.B. PayPal) hin');
      return true;
    }
    return false;
  }

  /*
   * Aktueller Bestellprozess vorhanden ?
   *
   * LocalStorage Werte für die Keys "subscriptionPurchaseTime" und "subscriptionPurchaseLocationHref"
   * deuten auf Payment-Rückkehrer hin,
   * wenn vor weniger als 600 Sekunden / 10 Minuten eine Bestellung mit identischer URL stattgefunden hat,
   * dann will der Nutzer wahrscheinlich den Bestellvorgang fortsetzen
   */
  purchaseExists () {
    this.logInstance.log('purchaseExists() called');
    let lsPurchaseTime = localStorage.getItem(LS_KEY_SUBSCRIPTION_PURCHASE_TIME);
    let lsPurchaseOfferId = localStorage.getItem(LS_KEY_SUBSCRIPTION_PURCHASE_OFFER_ID);
    let lsPurchaseLocationHref = localStorage.getItem(LS_KEY_SUBSCRIPTION_PURCHASE_LOCATION_HREF);
    // Wenn der LocalStorage Wert in subscriptionPurchaseTime nicht leer ist
    // Wenn der LocalStorage Wert in subscriptionPurchaseOfferId nicht leer ist
    // Wenn der LocalStorage Wert in subscriptionPurchaseLocationHref identischer der aktuellen URL ist
    if ( lsPurchaseTime !== null
      && lsPurchaseOfferId !== null
      && lsPurchaseOfferId !== ''
      && lsPurchaseLocationHref !== null
      && lsPurchaseLocationHref === window.location.href )
    {
      let currentTime = Math.floor(Date.now() / 1000);
      let diffInSec = currentTime - lsPurchaseTime;

      // Wenn vor weniger als 600 Sekunden (10 Minuten) eine Bestellung (mit identischer URL) stattgefunden hat,
      // dann will der Nutzer wahrscheinlich den Bestellvorgang fortsetzen
      if ( diffInSec <= SUBSCRIPTION_PURCHASE_TTL )
      {
        this.logInstance.log(`Der gefundene LocalStorage `+LS_KEY_SUBSCRIPTION_PURCHASE_TIME+`=${lsPurchaseTime} deutet auf Payment-Rückkehrer hin, wenn vor ${diffInSec} Sekunden eine Bestellung stattgefunden hat, dann will der Nutzer wahrscheinlich den Bestellvorgang fortsetzen`);
        this.logInstance.log('localStorage.getItem(' + LS_KEY_SUBSCRIPTION_PURCHASE_OFFER_ID + ') returns ' + lsPurchaseOfferId);
        this.logInstance.log('this.plenigoOfferId = ' + lsPurchaseOfferId);
        this.plenigoOfferId = lsPurchaseOfferId;
        return true;
      }
    }
    return false;
  }

  loadPlenigoSdk() {
    this.logInstance.log('loadPlenigoSdk() called');
    return new Promise((resolve, reject) => {
      if ( document.querySelector('#' + ID_PLENIGO_SDK) !== null ) {
        throw 'Plenigo SDK <script>-Tag existiert bereits';
      }
      this.logInstance.log("this.el.querySelector('#' + ID_PLENIGO_CHECKOUT)", this.el.querySelector('#' + ID_PLENIGO_CHECKOUT));
      const url = this.el.querySelector('#' + ID_PLENIGO_CHECKOUT)?.getAttribute("data-plenigojssdkurl");
      if ( typeof url !== "string" || url === "" ) {
        throw 'Plenigo SDK URL nicht gefunden';
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = resolve;
      script.onerror = reject;
      script.id = ID_PLENIGO_SDK;
      script.src = url;
      script.setAttribute("data-disable-redirect", "true");
      script.setAttribute("data-lang", "de");
      //document.head.append(script); doesn't work in ie11
      document.getElementsByTagName('head')[0].appendChild(script);
    })
    .then(() => {
      this.logInstance.log('loadPlenigoSdk Promise successful');
    })
    .catch((error) => {
      this.logInstance.log("Error loadPlenigoSdk Promise: " + error);
    });
  }

  /*
	 * Der redFACT REST Service "payment/plenigoPreparePurchase" wird aufgerufen,
	 * wenn im plenigo Checkout-iFrame ein Kauf-Prozess erfolgreich durchgeführt wurde.
	 * Es wird überprüft ob Zugriffsrechte bei Plenigo vorhanden sind.
   * Falls ja, wird aus der temp. Transaktion in payment_transaction eine Bestellung in payment_ticket angelegt.
   * Anschließend wird mit dem GET-Parameter "action=thankyou" die Artikel-Detailseite umgeleitet und ein "Danke"-Layer angezeigt.
   *
   */
  preparePurchase (e) {
    this.logInstance.log('preparePurchase() called');

    if ( this.preparePurchaseCalled ) {
      this.logInstance.log('preparePurchase() abgebrochen ... weil bereits aufgerufen');
      return;
    } else {
      this.preparePurchaseCalled = true;
    }

    // Eventuell befinden wir uns aus der Payment Standalone Seite
    if ( this.plenigoOfferId === '' ) {
      const tmpPlenigoOfferId = this.el.querySelector('#pay-standalone-page #pay-angebot')?.value;
      if ( typeof tmpPlenigoOfferId === 'string' && tmpPlenigoOfferId !== '' ) {
        this.plenigoOfferId = tmpPlenigoOfferId;
      }
    }

    // Plenigo Angebot-ID überprüfen
    if ( this.plenigoOfferId === '') {
      this.buildError('Es ist ein technisches Problem aufgetreten. Keine Plenigo Angebot-ID gefunden.');
      return;
    }

    // Endpoint gleich fetchInput definieren
    const endpoint = this.el.querySelector('#' + ID_PLENIGO_CHECKOUT)?.getAttribute("data-endpointpreparepurchase");
    if ( typeof endpoint !== 'string' || endpoint === '') {
      this.buildError(null, 'Attribut "data-endpointpreparepurchase" fehlt');
      return;
    }
  	const fetchInput = endpoint + `?rand=` + Math.random()
      + `&puid=` + nfyPu
      + `&offerid=` + this.plenigoOfferId
      + `&source=` + btoa(location.href);
  	this.logInstance.log(fetchInput);

    // @todo endpoint fest als constante pflegen ggf. in redFACT Helper Klasse
    const fetchInit = { method: "GET", credentials: "include" };
    window.fetch(fetchInput, fetchInit)
      .then((response) => response.json())
      .then((data) => {
        this.logInstance.log("preparePurchase fetch Promise response => " + JSON.stringify(data)); // {"success":true,"purchaseId":"1nOl2KHUTK7Knhj0rCaJ1AB5FbC","rfTransactionId":398978}
        if ( typeof data.success !== "undefined"
          && data.success
          && typeof data.purchaseid !== "undefined"
          && data.purchaseid !== ""
          && typeof data.rftransactionid !== "undefined"
          && data.rftransactionid !== "" )
        {
          this.plenigoPurchaseId = data.purchaseid;
          this.rfTransactionId = data.rftransactionid;
          localStorage.setItem(LS_KEY_RF_TRANSACTION_ID, this.rfTransactionId);
          if ( this.env.isArticleDetailPage() ) {
            localStorage.setItem(LS_KEY_SUBSCRIPTION_PURCHASE_OFFER_ID, this.plenigoOfferId);
            localStorage.setItem(LS_KEY_SUBSCRIPTION_PURCHASE_TIME, Math.floor(Date.now() / 1000));
            localStorage.setItem(LS_KEY_SUBSCRIPTION_PURCHASE_LOCATION_HREF, window.location.href);
          }
          this.renderPlenigoIFrame();
        } else {
          this.logInstance.log('preparePurchase fetch Rückgabe nicht verwendbar');
          if ( typeof data.error !== "undefined" && data.error !== "" ) {
            if ( typeof data.error_text !== "undefined" && data.error_text !== "" ) {
              this.buildError(data.error_text + ' - Fehler-Code ' + data.error);
            } else {
              this.buildError(ERROR_MESSAGE + ' - Fehler-Code ' + data.error);
            }
          } else {
            this.buildError();
          }
        }
      })
      .catch((error) => {
        this.logInstance.log("preparePurchase fetch Promise error: " + error);
        this.buildError();
      });
  }

  renderPlenigoIFrame () {
    this.logInstance.log('renderPlenigoIFrame() called');

    if ( this.plenigoPurchaseId !== '' ) {
      // start Checkout, put in purchaseId and elementId to start checkout
      new plenigo.Checkout(this.plenigoPurchaseId, { elementId: ID_PLENIGO_CHECKOUT }).start();
    } else {
      // purchase wiederherstellen z.B. wenn PayPal zurück umleitet
      new plenigo.start();
    }
  }

  /*
	 * Der redFACT REST Service "payment/plenigoPreparePurchase" legt eine temp. Transaktion in payment_transaction an,
	 * bereitet ein Kauf bei Plenigo vor und gibt die Plenigo purchaseId für die Generierung des Checkout-iFrames zurück
   */
  purchaseSuccess (e) {
    this.logInstance.log('purchaseSuccess() called');

    if ( this.env.isLocalhost() ) {
      this.logInstance.log('In Fractal Web UI Server purchaseSuccess() abbrechen');
      return;
    }

    // debugging Code:
    if (e.type !== "plenigo.PurchaseSuccess") {
      return false;
    }
    console.info("Event is: " + e.type);
    console.info(e);
    console.info("Custom data is: ", e.detail);

    if ( this.rfTransactionId === '' ) {
      var rfTransactionId = localStorage.getItem(LS_KEY_RF_TRANSACTION_ID);
      if ( rfTransactionId != null ) {
        this.rfTransactionId = rfTransactionId;
      }
    }


    var fetchInput = nfyDomain + `/REST/payment/plenigoSuccessPurchase?rand=` + Math.random()
      + `&puid=` + nfyPu
      + `&pageid=` + nfyJsonParams[`pageid`]
      + `&plenigoorderid=` + e.detail.orderId
      + `&rftransactionid=` + this.rfTransactionId;

    if ( Number.isInteger(Number.parseInt(window.nfyJsonParams[`arid`])) ) {
        fetchInput += `&dsid=` + nfyJsonParams[`arid`];
        fetchInput += `&shortcode=ar`;
    }
    const fetchInit = { method: "GET", credentials: "include" };
    window.fetch(fetchInput, fetchInit)
      .then((response) => response.json())
      .then((data) => {
        // data e.g. => {"success":true,"location":"https:\/\/www.mannheimer-morgen.de\/index.php?artikel=-startseite-tobias-test-artikel-&arid=1682473&puid=3&pageid=2031"}
        if ( typeof data.success === "boolean"
          && data.success
          && typeof data.location === "string"
          && data.location !== "" )
        {
          this.clearLocalStorage();
          // Umleiten auf Artikelseite mit "Danke" Paywall-Layer
          var successpage = data.location;
          successpage += successpage.match(/\?/) !== null ? "&":"?";
          location.href = successpage + "action=thankyou";
        } else {
          this.buildError();
        }
      })
      .catch((error) => {
        this.logInstance.log("Error purchaseSuccess(): " + error);
        this.buildError();
      });
  }

  /*
	 * Der redFACT REST Service "payment/quota" schaltet einen Artikel frei, d.h. legt eine redFACT Bestellung mit
	 * dem Produkt "Plus-Artikel Kontingent" an und leitet dann auf sich selbst um
	 * http://www.mannheimer-morgen.de/REST/payment/quota/ar/1205646/1192/1
   */
  quota (e) {
    this.logInstance.log('quota() called');
    const fetchInput = e.target.getAttribute('data-endpoint');
    this.logInstance.log('Paywall.quota() endpoint => ' + fetchInput);
    const fetchInit = { method: "GET", credentials: "include" };
    window.fetch(fetchInput, fetchInit)
      .then((response) => response.json())
      .then((data) => {
        this.logInstance.log("antwort von quota rest service");
        this.logInstance.log(data); // {"success":true,"location":"https:\/\/www.mannheimer-morgen.de\/index.php?artikel=-startseite-tobias-test-artikel-&arid=1682473&puid=3&pageid=2031"}
        if ( typeof data.success === "boolean"
          && data.success
          && typeof data.location === "string"
          && data.location !== "" )
        {
          location.href = data.location; // Umleiten auf Artikelseite
        } else {
          this.buildError();
        }
      })
      .catch((error) => {
        this.logInstance.log("Error quota(): " + error);
        this.buildError();
      });
  }

  /*
	 * Der redFACT REST Service "/REST/community/quotastatus" gibt einen int/number zurück, wie viele Plus-Artikel noch freigeschalten werden dürfen
	 *
	 * tfenrich@haas-xmac02 ~ % curl --request GET \
--header 'User-Agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36' \
--cookie 'rfFUS=0192bef70ac57036427c788cd86cdf12' \
"https://xmedias3.mannheimer-morgen.de/REST/community/quotastatus?puid=3"
   *
   */
  async getRemainQuota () {
    this.logInstance.log('getRemainQuota() called');

    let remainQuota = false;

    if ( this.isLayerVisible(ID_PAY_LAYER_QUOTA) ) {
      this.logInstance.log('getRemainQuota() abbrechen - Mehrfach REST-Anfragen vermeiden, falls der Layer "Plus-Artikel freischalten" bereits sichtbar ist');
      return remainQuota;
    }

    const endpoint = this.redfactHelper.getRestEndpointQuotastatus();
    this.logInstance.log('getRemainQuota() - window.fetch(' + endpoint + ', { method: "GET", credentials: "include" })');
    await window.fetch(endpoint, { method: "GET", credentials: "include" })
      .then((response) => response.json())
      .then((data) => {
        remainQuota = data.remainQuota;
      })
      .catch((error) => {
        this.logInstance.log("Error getRemainQuota(): " + error);
        const layerQuota = this.el.querySelector('#'+ID_PAY_LAYER_QUOTA);
        if ( layerQuota !== null ) {
          this.buildError(null, 'quotastatus abfrage fehlgeschlagen', layerQuota);
        }
      });

    return remainQuota;
  }

  /*
   * Methode korrigiert die Breadcrumb in dem ein <li>Schritt3</li> eingefügt wird,
   * dies ist nötig falls im 1. Schritt der autologin fehlschlägt und es doch einen 3. Schritt gibt
   */
  fixPaymentLayerBreadcrumb () {
    var node = this.el.querySelector('#'+ID_PAY_LAYER_PAYMENT)?.querySelector('div > ul');
    if ( node !== null ) {
      node.querySelectorAll('li')[1].classList.remove(CLASS_BREADCRUMB_ACTIVE);
      let li = document.createElement('li')
      li.innerHTML = 'Schritt 3';
      li.classList.add(CLASS_BREADCRUMB_ACTIVE);
      node.appendChild(li)
    }
  }

  clearLocalStorage () {
    localStorage.removeItem(LS_KEY_SUBSCRIPTION_PURCHASE_TIME);
    localStorage.removeItem(LS_KEY_SUBSCRIPTION_PURCHASE_LOCATION_HREF);
    localStorage.removeItem(LS_KEY_SUBSCRIPTION_PURCHASE_OFFER_ID);
    localStorage.removeItem(LS_KEY_RF_TRANSACTION_ID);
  }

  toAnchor () {
    this.logInstance.log('toAnchor() called');
    const el = this.el.querySelector('#' + ID_PAY_CHECKOUTFLOW);
    if ( el != null ) {
      const rect = el.getBoundingClientRect();
      window.scrollTo({top: (document.documentElement.scrollTop + rect.top)});
    }
  }

  buildError(explictMessage = null, explictLogMessage = null, target = null) {
    this.logInstance.log('buildError() called');
    if (explictLogMessage !== null && explictLogMessage !== '') {
      this.logInstance.log('explictLogMessage = ' + explictLogMessage);
    }
    let message = ERROR_MESSAGE;
    if (explictMessage !== null && explictMessage !== '') {
      message = explictMessage;
    }
    if ( target === null && this.selectedLayerNode !== null ) {
      target = this.selectedLayerNode;
    }
    if ( target !== null ) {
      var el = target.querySelector('.core-login__error,.core-register__error,.paywall__error,.paywall-steps__error');
      if ( el !== null ) {
        this.toAnchor();
        el.innerHTML = message;
        el.classList.remove('core-login--hidden', 'core-register--hidden', CLASS_PAYWALL_HIDDEN);
      } else {
        this.logInstance.log('Keine Error-Container gefunden für Fehlermeldung => ' + message);
      }
    }
  }
}
