import { InitializeLazy } from 'pacto';


export class Action extends InitializeLazy {
  get settings() {
    return {
      selector: '.modal',
    };
  }

  get import() {
    return import(/* webpackChunkName: "common-modal" */ './Initialize');
  }

  // Bypass the logic inside the setup function (ignore intersection observer)
  // and fetch module directly when elements of modal are in the DOM.
  /* eslint-disable no-underscore-dangle */
  _setup() {
    this._fetch();
  }
  /* eslint-enable no-underscore-dangle */
}
